/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import isMatch from 'lodash/isMatch.js';
import { getConfig } from './config.js';
import { bbLogger } from './utilities/logger.js';
import { dom } from './global.js';
import { urlQueryAsObject } from './utilities/queryParams.js';
import defaultsDeep from './utilities/helpers/defaultsDeep.js';
import { unitRegistry, unitTemplates, getUnitCollection, getUnits } from './unitManager.js';
import { hookedFn } from './utilities/hookedFunction.js';
import omit from './utilities/helpers/omit.js';
import { exposureApi } from './exposureApi.js';
import { storage } from './services/storage.js';
import variableChecker from './utilities/variableChecker.js';
import { eventEmitter } from './events.js';
import CONSTANTS from './constants.json';
import { errorReporting } from './services/errorReporting.js';
import { pageTargetingObj } from './pageTargeting.js';

let query = urlQueryAsObject();
let autoTargetingApplied = false;
// Query param constants
const { AD_SESSION, AD_SUBSESSION, SESSION, SUBSESSION, FTAG, TTAG, TARGETING_PREFIX } = CONSTANTS.QUERY_PARAMS;
// Events
const { AUCTION, INIT_PAGE_TARGETING_SET} = CONSTANTS.EVENTS;

export function resetQuery() {
	query = urlQueryAsObject();
}

/**
 * Page targeting object
 *
 * @type {Object}
 * @memberof Targeting
 * @exposed readonly
 */
// export let pageTargeting = richObject({});
// eslint-disable-next-line prefer-const, import/no-mutable-exports
export let pageTargeting = pageTargetingObj;
/**
 * Getter method for getting the page targeting
 *
 * @returns {Object} page targeting key/value object
 * @memberof Targeting
 * @private
 */
export function getPageTargeting(...args) {
	return pageTargeting.getValue(...args);
}

/**
 * Method getter for the vguid value
 *
 * @returns {String|undefined} The vguid value(if intercepted)
 * @memberof Targeting
 * @private
 */
export function getVGuid() {
	return getPageTargeting('vguid');
}

/**
 * The setTargeting used internally by BidBarrel
 *
 * Hookable function
 *
 * @param {BidBarrel~AdUnit[]} - ad unit collection
 * @memberof Targeting
 * @type {Function}
 * @method
 * @private
 */
export const setBidTargeting = hookedFn('sync', (unitCollection) => {
	bbLogger.logInfo('Setting Bid Targeting', unitCollection);
	return unitCollection;
});
// Flags to assist with config subscriptions
let subscribed = false;
let skipInitRun = true;

/**
 * Hookable Function for setting an individual unit's targeting
 *
 * @param {String} unitCode unit code identifier
 * @param {String} key targeting key
 * @param {String} value targeting value
 * @param {Boolean} [templates] apply targeting ot templates
 * @method
 * @private
 * @memberof Targeting
 */
export const setUnitTargeting = hookedFn('sync', (unitCode, key, value, templates = false) => {
	if (!unitRegistry[unitCode].targeting) {
		unitRegistry[unitCode].targeting = {};
	}
	unitRegistry[unitCode].targeting[key] = value;
	if (templates) {
		if (!unitTemplates[unitCode].targeting) {
			unitTemplates[unitCode].targeting = {};
		}
		unitTemplates[unitCode].targeting[key] = value;
	}
});
/**
 * Hookable Function for setting the page targeting
 *
 * @param {String} key
 * @param {String} value
 * @method
 * @private
 * @memberof Targeting
 */
export const setPageTargeting = hookedFn('sync', (key, value) => {
	pageTargeting.setValue(key, value);
	// pageTargeting[key] = value;
});
/**
 * Hookable function that clears keys from page targeting
 *
 * @param {String[]} keys
 * @method
 * @private
 * @memberof Targeting
 */
export const clearPageTargeting = hookedFn('sync', (keys) => {
	const _currentValue = pageTargeting.getValue();
	let newValue = {};
	if (Array.isArray(keys) && keys.length > 0) {
		newValue = omit(_currentValue, keys);
	}
	pageTargeting.setValue('*', newValue, { forceUpdate: true });
});
/**
 * Hookable function to clear unit targeting
 *
 * @param {String} unitCode unit code identifier
 * @param {String[]} keys
 * @param {Object} [registry=unitRegistry] Option registry object
 * @method
 * @private
 * @memberof Targeting
 */
export const clearUnitTargeting = hookedFn('sync', (unitCode, keys, registry = unitRegistry) => {
	if (Array.isArray(keys) && keys.length > 0) {
		registry[unitCode].targeting = omit(unitRegistry[unitCode].targeting, keys);
	} else {
		registry[unitCode].targeting = {};
	}
});
/**
 * Takes all registered url query params and overrides existing targeting
 *
 * @param {object} currentTargeting
 * @param {object} query
 * @returns {object}
 * @private
 * @memberof Targeting
 * @returns {object} passed in currentTargeting with query targeting overrides
 */
function getQueryTargeting(currentTargeting, queryTargeting = false) {
	const query = queryTargeting || urlQueryAsObject(dom().window.location.href);
	const dynamicPrefix = getConfig('targeting.query.dynamicPrefix');
	const keyMap = getConfig('targeting.query.keyMap');
	const constantQueryTargetingKeys = {
		[AD_SESSION]: 'session',
		[AD_SUBSESSION]: 'subses',
		[SESSION]: 'session',
		[SUBSESSION]: 'subses',
		[FTAG]: 'ftag',
		[TTAG]: 'ttag',
	};
	const result = currentTargeting;
	Object.keys(query).forEach((key) => {
		if (Object.prototype.hasOwnProperty.call(query, key)) {
			const queryValue = query[key];
			if (dynamicPrefix && key.indexOf(dynamicPrefix) >= 0) {
				result[key.replace(dynamicPrefix, '')] = queryValue;
			}
			if (keyMap[key]) {
				result[keyMap[key]] = queryValue;
			}
			if (key.indexOf(TARGETING_PREFIX) >= 0) {
				result[key.replace(TARGETING_PREFIX, '')] = queryValue;
			}
			if (constantQueryTargetingKeys[key]) {
				result[constantQueryTargetingKeys[key]] = queryValue;
			}
		}
	});
	return result;
}
/**
 * Applies and handles auto targeting while also applying
 * query param targeting overrides
 *
 * @param {object} pageProvidedTargeting
 * @param {object} queryTargeting
 * @param {boolean} incrementPvValue
 * @memberof Targeting
 * @private
 * @returns {object} auto targeting values
 */
function applyAutoTargeting(pageProvidedTargeting, queryTargeting, incrementPvValue = true) {
	autoTargetingApplied = true;
	if (!queryTargeting) {
		queryTargeting = getQueryTargeting({});
	}
	const config = getConfig();
	const { consolidate } = config.targeting.cookie;
	const { keyMap } = config.targeting.cookie;
	let result = {};

	const surroundCookie = storage.getCookie(keyMap.surround || 'surround');
	let surround = null;
	if (surroundCookie) {
		surround = surroundCookie.split('|');
		if (consolidate) {
			storage.deleteCookie(keyMap.surround || 'surround');
		}
	} else {
		const availableSessions = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'].slice(
			0,
			config.targeting.seats.session
		);
		const session = availableSessions[Math.floor(Math.random() * availableSessions.length)];
		const subsession = Math.floor(Math.random() * config.targeting.seats.subsession) + 1;
		surround = [session, subsession];
	}

	const bbCookies = [storage.getCookie('browserSession'), storage.getCookie('dailySession')];
	const existingCookies = {
		session: surround[0],
		subses: surround[1],
	};
	const cookieKeyMapKeys = ['firstpg', 'session', 'subses', 'ftag', 'ttag'];
	for (let i = 0; i < cookieKeyMapKeys.length; i += 1) {
		const key = cookieKeyMapKeys[i];
		if (keyMap[key]) {
			const value = storage.getCookie(keyMap[key]);
			if (value) {
				existingCookies[key] = value;
				if (consolidate) {
					storage.deleteCookie(keyMap[key]);
				}
			}
		}
	}
	bbLogger.atVerbosity(2).logInfo('Handling autotargeting.', 'Query:', queryTargeting, 'Publisher Provided:', pageProvidedTargeting, 'Cookies:', existingCookies);
	const browserSessionData = bbCookies[0] ? bbCookies[0] : {};
	const dailySessionData = bbCookies[1] ? bbCookies[1] : {};
	if (consolidate) {
		// using the consolidated cookies
		result = {
			// firstpg: parseInt(queryTargeting.firstpg || pageProvidedTargeting.firstpg || dailySessionData.firstpg || existingCookies.firstpg, 10),
			ttag: queryTargeting.ttag || pageProvidedTargeting.ttag || dailySessionData.ttag || existingCookies.ttag,
			ftag: queryTargeting.ftag || pageProvidedTargeting.ftag || dailySessionData.ftag || existingCookies.ftag,
			session: queryTargeting.session || pageProvidedTargeting.session || browserSessionData.session || existingCookies.session,
			subses: queryTargeting.subses || pageProvidedTargeting.subses || browserSessionData.subses || existingCookies.subses,
			pv: parseInt(queryTargeting.pv || pageProvidedTargeting.pv || dailySessionData.pv || existingCookies.pv || 0, 10),
		};
	} else {
		// using other cookies
		result = {
			// firstpg: parseInt(queryTargeting.firstpg || pageProvidedTargeting.firstpg || existingCookies.firstpg || dailySessionData.firstpg, 10),
			pv: parseInt(queryTargeting.pv || pageProvidedTargeting.pv || existingCookies.pv || dailySessionData.pv || 0, 10),
			session: queryTargeting.session || pageProvidedTargeting.session || existingCookies.session || browserSessionData.session,
			subses: queryTargeting.subses || pageProvidedTargeting.subses || existingCookies.subses || browserSessionData.subses,
			ttag: queryTargeting.ttag || pageProvidedTargeting.ttag || existingCookies.ttag || dailySessionData.ttag,
			ftag: queryTargeting.ftag || pageProvidedTargeting.ftag || existingCookies.ftag || dailySessionData.ftag,
		};
		storage.deleteCookie('browserSession');
		storage.deleteCookie('dailySession');
	}

	if (typeof result.pv !== 'undefined' && !(pageProvidedTargeting.pv || queryTargeting.pv) && incrementPvValue) {
		result.pv += 1;
	}

	// if (result.firstpg !== 0 && result.firstpg !== 1 && typeof result.pv !== 'undefined') {
	// 	// eslint-disable-next-line eqeqeq
	// 	result.firstpg = result.pv == 1 ? '1' : '0';
	// } else if (result.firstpg !== 0 && result.firstpg !== 1) {
	// 	result.firstpg = 1;
	// } else if (result.firstpg === 1) {
	// 	result.firstpg = 0;
	// }

	if (consolidate) {
		const bbBrowserSession = { session: result.session, subses: result.subses };
		const bbDailySession = { firstpg: result.firstpg, ttag: result.ttag, ftag: result.ftag, pv: result.pv };
		if (!isMatch(bbCookies[0], bbBrowserSession)) {
			storage.setCookie('browserSession', bbBrowserSession);
		}
		if (!isMatch(bbCookies[1], bbDailySession)) {
			storage.setCookie('dailySession', bbDailySession);
		}
	} else if (keyMap.surround) {
		const newSurroundCookie = [result.session, result.subses].join('|');
		if (surroundCookie !== newSurroundCookie) {
			storage.setCookie(keyMap.surround || 'surround', [result.session, result.subses].join('|'));
		}
	} else {
		if (result.session !== existingCookies.session) {
			storage.setCookie(keyMap.session || 'session', result.session);
		}
		if (result.subses !== existingCookies.subses) {
			storage.setCookie(keyMap.subses || 'subses', result.subses);
		}
	}

	if (!consolidate) {
		if (result.ftag !== existingCookies.ftag) {
			storage.setCookie(keyMap.ftag || 'ftag', result.ftag);
		}
		if (result.ttag !== existingCookies.ttag) {
			storage.setCookie(keyMap.ttag || 'ttag', result.ttag);
		}
		// if (result.firstpg !== existingCookies.firstpg) {
		// 	storage.setCookie(keyMap.firstpg || 'firstpg', result.firstpg);
		// }
	}

	return defaultsDeep({}, result, queryTargeting, pageProvidedTargeting);
}
// Variable for onChange unsubscribe
let cancelAutoHandling;
// Manual observer variable to track
let pageViewObserver;
/**
 * Updates the daily session cookie
 *
 * @memberof Targeting
 * @private
 */
function updateDailySessionCookie() {
	const { ttag, ftag, pv } = getPageTargeting();
	const firstpg = 0; // firspg is no longer used REVSYS-1868
	if (getConfig('targeting.cookie.consolidate')) {
		storage.setCookie('dailySession', { firstpg, ttag, ftag, pv });
	} else {
		const values = { ttag, ftag, pv };
		const keyMap = getConfig('targeting.cookie.keyMap');
		Object.keys(values).forEach((key) => {
			if (Object.prototype.hasOwnProperty.call(values, key)) {
				const value = values[key];
				if (keyMap[key]) {
					storage.setCookie(keyMap[key], value);
				}
			}
		});
	}
}
/**
 * Handles incrementing the pv targeting value. Explicitly calling this via `BidBarrel.newPage()` will disable automatic handling of pv value.
 *
 * This method is automatically invoked when the pageTargeting vguid targeting value is changed.
 *
 * @memberof Targeting
 * @private
 * @exposed
 * @exposedAs newPage
 * @method
 */
function incrementPv(newGuid) {
  const vguid = newGuid || getVGuid();
	const auto = getConfig('targeting.auto');
	if (auto) {
		const currentPvValue = pageTargeting.getValue('pv') || 0;
		const newPvValue = parseInt(currentPvValue, 10) + 1;
		bbLogger.logInfo('Incrementing PV targeting value to', newPvValue, 'Page View Guid detected', vguid);
		setPageTargeting('pv', newPvValue);
		updateDailySessionCookie();
	}
}
/**
 * Handles setting up automatic logic around incrementing the pv targeting value
 *
 * @memberof Targeting
 * @private
 */
function setupPv() {
	if (getConfig('targeting.auto') && !pageViewObserver) {
		pageViewObserver = variableChecker(() => pageTargeting.getValue('vguid'));

		eventEmitter.on(AUCTION, () => pageViewObserver.check());

		cancelAutoHandling = pageViewObserver.onChange(incrementPv, { skipInitialRun: true });
	}
}

/**
 * This method evaluates application of targeting overrides
 * specifically for query and autotargeting overrides
 *
 * @param {object} pageTargeting publisher provided page targeting
 * @param {object|boolean} queryTargeting
 * @param {boolean} forceRun
 * @param {boolean} increment
 * @memberof Targeting
 * @returns {object} targeting with overrides
 * @private
 */
export function applyTargeting(pageTargeting, queryTargeting = false, forceRun = false, increment = true) {
	const config = getConfig();
	let result = pageTargeting || {};
	if (config.targeting.auto && (!autoTargetingApplied || forceRun)) {
		// handles ftag, ttag, session, subsession
		result = applyAutoTargeting(result, queryTargeting, increment);
	} else {
		result = getQueryTargeting(result, queryTargeting);
	}
  eventEmitter.emit(INIT_PAGE_TARGETING_SET, result);
	return result;
}

/**
 * Takes the current page targeting and checks if there is a vguid specified
 * @param {Object} pageTargeting current page targeting
 * @memberof Targeting
 * @private
 * @returns {object}
 */
function applyVGuid(providedTargeting) {
	if (!providedTargeting.vguid && !getVGuid()) {
		bbLogger.logError('No vguid provided! Please update your implementation to provide a vguid in initConfig.pageTargeting.vguid');
		const errorObj = new Error(`No vguid provided! Please update your implementation to provide a vguid in initConfig.pageTargeting.vguid.`);
		errorReporting.report(errorObj);
	}
	return providedTargeting;
}

/**
 * Method for clearing targeting on either the page level or unit level
 *
 * *NOTE:* When clearing out targeting at the page level keep in mind Google will not clear out
 * targeting that was set on individual units explicitly.
 *
 * @param {string[]} targetingKeys array of string keys to clear out (leave empty for all)
 * @param {string[]} unitCodes array of unit codes to perform on (leave empty to run global)
 * @param {boolean} [applyToTemplates=false] apply changes to templates(used as template for inc units)
 * @public
 * @memberof Targeting
 * @exposed
 */
export function clearTargeting(targetingKeys = [], unitCodes = [], applyToTemplates = false) {
	if (!unitCodes || !unitCodes.length) {
		clearPageTargeting(targetingKeys);
		bbLogger.atVerbosity(2).logInfo('Clearing targeting for page', targetingKeys || 'all values');
	} else {
		bbLogger.atVerbosity(2).logInfo('Clearing targeting for slots', unitCodes, targetingKeys);
		const units = getUnitCollection(unitCodes);
		for (let index = 0; index < units.length; index += 1) {
			const { code } = units[index];
			clearUnitTargeting(code, targetingKeys);
			if (unitTemplates[code] && applyToTemplates) {
				clearUnitTargeting(code, targetingKeys, unitTemplates);
			}
		}
	}
}
/**
 * Sets targeting for a slot or all slots if no slot is provided
 *
 * <b>NOTE:</b> Due to BidBarrel being unaware of incremental units until the auction is ran you will need to use an alternative syntax to set targeting on incremental units. When passing the `[ 'mpu-inc', 2]` into `BidBarrel.auction(unitCodes)` you can provide a third argument like so: `[ 'mpu-inc', 2, {pos: 'inc-pos-targeting-value'}]`. That third array item is equivalent to the first argument of `BidBarrel.setTargeting(targetingObj, unitKeys)`. This method is the proper method to set targeting on incremental units. As of BidBarrel v2.4.9 this also works for normal units simply pass null for the increment value like so: `[ 'mpu-top', null, {pos: 'top'}]`.
 * @param {object} targeting
 * @param {String|String[]} [unitKeys]
 * @param {boolean} [applyToTemplates=false] apply changes to templates (used as template for inc units)
 * @memberof Targeting
 * @exposed
 */
export function setTargeting(targeting, unitKeys, applyToTemplates = false) {
	if (!targeting) return;
	if (!unitKeys || !unitKeys.length) {
		bbLogger.atVerbosity(2).logInfo('Applying targeting values', targeting, 'to page');
	} else {
		bbLogger.atVerbosity(2).logInfo('Applying targeting values', targeting, 'to units', unitKeys);
	}
	if (typeof unitKeys === 'string') unitKeys = [unitKeys];
	if (!unitKeys) {
		const config = getConfig();
		Object.keys(targeting).forEach((targetingKey) => {
			if (Object.prototype.hasOwnProperty.call(targeting, targetingKey)) {
				const targetingValue = targeting[targetingKey];
				if (typeof targetingValue !== 'undefined' && targetingValue !== null) {
					setPageTargeting(targetingKey, targetingValue);
				} else if (['ftag', 'ttag'].indexOf(targetingKey) === -1 || !config.targeting.auto) {
					bbLogger.atVerbosity(3).logWarn(`Targeting value is null or undefined for "${targetingKey}". This will result in the targeting value not being set.`);
				}
			}
		});
	} else {
		const units = getUnitCollection(unitKeys);
		for (let i = 0; i < units.length; i += 1) {
			const unitCode = units[i].code;
			Object.keys(targeting).forEach((targetingKey) => {
				if (Object.prototype.hasOwnProperty.call(targeting, targetingKey)) {
					const targetingValue = targeting[targetingKey];
					if (typeof targetingValue !== 'undefined' && targetingValue !== null) {
						setUnitTargeting(unitCode, targetingKey, targetingValue);
						if (applyToTemplates) {
							setUnitTargeting(unitCode, targetingKey, targetingValue, unitTemplates);
						}
					} else {
						bbLogger.atVerbosity(3).logWarn(`Targeting value is null or undefined for "${unitCode}:${targetingKey}". This will result in the targeting value not being set.`);
					}
				}
			});
		}
	}
}
/**
 * Method for clearing all targeting and applying a whole new object for targeting. This method
 * will also pickup cookie and query string values again.
 *
 * *NOTE:* If the url has changed and the old query string parameters are no longer there
 * the old keys will not be applied unless you pass the third arguement of `true`
 *
 * @param {Object|void} newTargeting an object of key/value pairs to set as new targeting
 * @param {string[]} [unitCodes=[]] array of unit codes to reset targeting on.
 * @param {boolean} [preserveQuery=false] flag to preserve pre-existing query string paramaters
 * @param {boolean} [applyToTemplates=false] apply changes to templates(used as template for inc units)
 * @exposed
 */
export function resetTargeting(newTargeting, unitCodes = [], preserveQuery = false, applyToTemplates = false) {
	if (newTargeting && typeof newTargeting === 'object' && newTargeting.constructor === Array) {
		bbLogger.logError('The first argument of BidBarrel.resetTargeting should be an object or falsy');
		const errorObj = new Error(`The first argument of BidBarrel.resetTargeting should be an object or falsy.`);
		errorReporting.report(errorObj);
		return;
	}
	if (unitCodes && unitCodes.constructor !== Array) {
		bbLogger.logError('The second argument of BidBarrel.resetTargeting should be an array or falsy value. Exiting.');
		const errorObj = new Error(`The second argument of BidBarrel.resetTargeting should be an array or falsy value. Exiting.`);
		errorReporting.report(errorObj);
		return;
	}
	if (typeof preserveQuery !== 'boolean' && preserveQuery !== null && preserveQuery !== undefined) {
		bbLogger.logError('The third argument of BidBarrel.resetTargeting should be a boolean or falsy value. Exiting.');
		const errorObj = new Error(`The third argument of BidBarrel.resetTargeting should be a boolean or falsy value. Exiting.`);
		errorReporting.report(errorObj);
		return;
	}
	clearTargeting([], unitCodes, applyToTemplates);
	if (!unitCodes || unitCodes.length === 0) {
		autoTargetingApplied = false;
		setTargeting(applyTargeting(newTargeting, preserveQuery ? query : false, false, false));
	} else {
		setTargeting(newTargeting, unitCodes, applyToTemplates);
	}
}
/**
 * Resets all targeting for the page and units
 *
 * *NOTE:* If the url has changed and the old query string parameters are no longer there
 * the old keys will not be applied unless you pass the third arguement of `true`
 *
 * @param {Object|void} newTargeting new page level targeting
 * @param {boolean} [preserveQuery=false] flag to preserve pre-existing query string paramaters
 * @param {boolean} [resetTemplates=false] apply changes to templates(used as template for inc units)
 * @memberof Targeting
 * @exposed
 */
export function resetAllTargeting(newTargeting, preserveQuery = false, resetTemplates = false) {
	const displayedUnitCodes = [];
	const unitRegistry = getUnits();
	Object.keys(unitRegistry).forEach((unitCode) => {
		if (Object.prototype.hasOwnProperty.call(unitRegistry, unitCode)) {
			const unit = unitRegistry[unitCode];
			if (unit.displayed) {
				displayedUnitCodes.push(unit.code);
			}
		}
	});
	clearTargeting([], displayedUnitCodes, resetTemplates);
	resetTargeting(newTargeting, [], preserveQuery, resetTemplates);
}

/**
 * Subscribes to config targeting changes
 *
 * @memberof Targeting
 * @private
 */
function subscribeToConfigTargetingChanges() {
	if (subscribed) return;
	subscribed = true;
	getConfig('siteTargeting', (pageTargeting) => {
		if (!skipInitRun) {
			setTargeting(pageTargeting);
		}
		skipInitRun = false;
	});
	getConfig('pageTargeting', (pageTargeting) => {
		if (!skipInitRun) {
			setTargeting(pageTargeting);
		}
		skipInitRun = false;
	});
}

/**
 *  Create an iframe in the page, load it with the iFrameUri, then call the callback when the message is received
 *
 * @param {*} iFrameUri url of the page to load in the iframe
 * @param {*} callBack function to call when the message is received
 */

function _3pcookieTest(iFrameUri, callBack) {
  const frame = dom().window.document.createElement('iframe');
  frame.src = iFrameUri;
  frame.sandbox = "allow-scripts allow-same-origin";
  frame.style.display = 'none';
  frame.onload = () => {
    frame.contentWindow.postMessage(JSON.stringify({ 'test': 'cookie' }), '*');
  };

  const messageHandler = (event) => {
    if (event.origin !== new URL(iFrameUri).origin) return;
    try {
      const data = JSON.parse(event.data);
      if (data && data.result) {
        callBack(data.result);
        dom().window.removeEventListener('message', messageHandler);
        dom().window.document.body.removeChild(frame);
      }
    } catch (error) {
      bbLogger.logError('Error parsing message from 3pc message response:', error);
    }
  };

  dom().window.addEventListener('message', messageHandler);
  dom().window.document.body.appendChild(frame);
}

/**
 * Function to call when 3pc response is received.  This will log the result and set the targeting value
 *
 * @param {boolean} x indicates whether 3rd party cookies are enabled
 */
function save3pcResult(x) {
  bbLogger.logInfo(`3pc: 3rd party cookies enabled: ${x}`);
  setTargeting({ '_3pc': x });
}

/**
 * Checks to see if 3rd party cookie testing is enabled, if so it will call the 3pcookieTest function
 */

function set3pc() {
  const config=getConfig();
  if (config.targeting.track3pc.enabled) {
    _3pcookieTest(config.targeting.track3pc.url, save3pcResult);
  }
}

/**
 * Function for setting initial page targeting
 *
 * @param {object} targeting
 * @param {boolean} forceRun
 * @memberof Targeting
 * @method
 * @private
 */
export const setInitialPageTargeting = hookedFn('sync', (targeting, forceRun = false) => {
	setTargeting(applyVGuid(applyTargeting(targeting, false, forceRun)));
	setupPv();
  set3pc();
	subscribeToConfigTargetingChanges();
});

exposureApi.rootScope({
	setTargeting,
	resetTargeting,
	resetAllTargeting,
	clearTargeting,
	newPage: () => {
		cancelAutoHandling();
		incrementPv();
	},
});

exposureApi.rootScopeGetters({
	vguid: () => getVGuid(),
	pageTargeting: () => pageTargeting.getPageTargeting(),
});
