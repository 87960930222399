import { setTargeting, getPageTargeting } from '../../targeting.js';
import { logger } from '../../utilities/logger.js';
import { storage } from '../../services/storage.js';
import { setConfig } from '../../config.js';
import { getEnvCfg } from '../../utilities/environment.js';
import { errorReporting } from '../../services/errorReporting.js';
import variableChecker from '../../utilities/variableChecker.js';
import { eventEmitter } from '../../events.js';
import errorReplacer from '../../utilities/helpers/errorReplacer.js';
import isEmpty from '../../utilities/helpers/isEmpty.js';
import CONSTANTS from '../../constants.json';

const { dom } = require('../../global.js');

const { AUCTION, HEADER_BIDDING_REQUEST, ENR_FIRED, ENR_RETURNED, TAGULAR_READY } = CONSTANTS.EVENTS;

const enrLogger = logger({ name: 'enrichment', textColor: '#FFF', bgColor: '#002200' });
const observer = variableChecker('cohesion'); // the function used to check if Cohesion is ready
/**
 * Configuration object for Enrichment module
 *
 * @memberof Enrichment
 * @private
 */
let enrConfig = {};
/**
 * Enrichment Confg getter
 * @returns {Object} Enrichment config
 */
function getEnrConfig() {
	return enrConfig;
}
/**
 * Enrichment Config setter
 * @param {*} config Config to save as Enrichment Config
 */
function setEnrConfig(config) {
	enrConfig = config;
}

/**
 * Sets the targeting values for Enrichment
 * @param {Object} enrichmentTargetingObj Object to add to targeting. eg. {useg: 's_42'}
 * @private
 * @memberof Enrichment
 */
function setEnrichmentTargeting(enrichmentTargetingObj) {
	if (!isEmpty(enrichmentTargetingObj)) {
		enrLogger.logInfo('Setting Enrichment Targeting', enrichmentTargetingObj);
		if (!isEmpty(enrichmentTargetingObj) && !/^(noxs|noenr|enrerr)$/.test(enrichmentTargetingObj.useg)) {
			// do not store 'enrcache' in the enrichment cookie
			const re = /,?enrcache/;
			storage.setCookie('enrichment', enrichmentTargetingObj.useg.replace(re, ''));
			// blow useg up into an array of strings and store that for rubicon ucat https://docs.prebid.org/dev-docs/bidders/rubicon.html
			if (enrConfig.enrichPrebid === true && typeof pbjs !== 'undefined') {
				// eslint-disable-next-line no-undef
				pbjs.setBidderConfig({
					bidders: ['rubicon'],
					config: {
						ortb2: {
							user: {
								ext: {
									data: {
										ucat: enrichmentTargetingObj.useg
											.replace(re, '')
											.split(',')
											.map((item) => item.trim()),
									},
								},
							},
						},
					},
				});
			}
		}
		setTargeting(enrichmentTargetingObj);
	}
}
/**
 * Function to retrieve specific GAM keys from AdLib
 * @returns object containing specific GAM keys
 */
function getPageValues() {
	const pageTargeting = {};
	pageTargeting.pv = typeof getPageTargeting().pv !== 'undefined' ? getPageTargeting().pv : '';
	if (typeof getPageTargeting().cid !== 'undefined') {
		// eslint-disable-next-line prefer-destructuring
		pageTargeting.postId = getPageTargeting().cid.split(',')[1];
	}
	// Context tags passed by page
	// search
	if (typeof getPageTargeting().topic !== 'undefined') {
		pageTargeting.keywords = `${getPageTargeting().topic.toString()}`;
	}
	// if (typeof getPageTargeting().clusterId !== 'undefined') {
  //   const uniqueClusterIds = [...new Set(getPageTargeting().clusterId.split(/[,.]/))];
  //   const segtaxObjs = [];
  //   for(let i = 0; i < uniqueClusterIds.length; i+=1){
  //     segtaxObjs.push( {id: uniqueClusterIds[i]} );
  //   }
  //   pageTargeting.segtax = segtaxObjs;
  //   // MLS also need to set ortb2.site.pagecat
	// }
	if (typeof getPageTargeting().seccat !== 'undefined') {
		pageTargeting.sectioncat = getPageTargeting()
			.seccat.split(',')
			.map((item) => item.trim());
	}
	return pageTargeting;
}
/**
 * Function to set some ortb2 values
 *
 */
function addContextToOrtb2() {
	const pageEnrValue = getPageValues();
	let changed = 0;
	if (enrConfig.enrichPrebid === true && typeof pbjs !== 'undefined') {
		// eslint-disable-next-line no-undef
		if (typeof pageEnrValue.keywords !== 'undefined' && pageEnrValue.keywords !== pbjs.getConfig('ortb2.site.keywords')) {
			// eslint-disable-next-line no-undef
			pbjs.mergeConfig({ ortb2: { site: { keywords: pageEnrValue.keywords } } });
			setConfig('prebid.pbjsConfig.ortb2.site.keywords', pageEnrValue.keywords);
			changed = 1;
		}
		// eslint-disable-next-line no-undef
		if (typeof pageEnrValue.sectioncat !== 'undefined' && pageEnrValue.sectioncat !== pbjs.getConfig('ortb2.site.sectioncat')) {
			// eslint-disable-next-line no-undef
			pbjs.mergeConfig({ ortb2: { site: { sectioncat: pageEnrValue.sectioncat } } });
			setConfig('prebid.pbjsConfig.ortb2.site.sectioncat', pageEnrValue.sectioncat);
			changed = 1;
		}
		// eslint-disable-next-line no-undef
		if (typeof pageEnrValue.segtax !== 'undefined') {
			const segData = [{ name: 'RedVentures Content Platform', ext: { segtax: 7 }, segment: [pageEnrValue.segtax] }];
			// eslint-disable-next-line no-undef
			pbjs.mergeConfig({ ortb2: { site: { content: { data: segData } } } });
			setConfig('prebid.pbjsConfig.ortb2.site.content.data', segData);
			// also need to setup ortb2.site.pagecat
			changed = 1;
		}

		if (changed === 1) {
			// eslint-disable-next-line no-undef
			enrLogger.logInfo('Added to Enrichment ORTB2 context:', pbjs.getConfig('ortb2'));
		}
	}
}
/**
 * Transforms Enrichment Api data into the proper format for targeting
 * convert Enrichment response into GAM targeting params
 * push the key to a string array
 * return the targeting object
 *
 * @param {object} enrData Enrichment api response data
 * @returns {object} targeting object
 * @private
 * @memberof Enrichment
 */
function toTargeting(enrData) {
	let segments = [];
	let targObj = {};
	if (typeof enrData === 'object') {
		// segment values have priority, add them first
		Object.getOwnPropertyNames(enrData).forEach((tr) => {
			if (enrData[tr] !== 'makeDNAFullAudiences' && enrData[tr] === true) {
				segments.push(tr);
			}
		});
		if (
			typeof enrData.makeDNAFullAudiences !== 'undefined' &&
			enrData.makeDNAFullAudiences !== null &&
			typeof enrData.makeDNAFullAudiences.audiences !== 'undefined' &&
			enrData.makeDNAFullAudiences.audiences.length > 0
		) {
			// makeDNAFullAudiences.audiences should be an array of strings
			segments = segments.concat(enrData.makeDNAFullAudiences.audiences);
		}
	}
	// de-dup
	segments = segments.filter((v, i, a) => typeof v === 'string' && v.length > 0 && a.indexOf(v) === i);
	if (segments.length > 0) {
		targObj = { useg: segments.join() };
	} else {
		targObj = { useg: '0' };
	}
	return targObj;
}
/**
 * Gets the API endpoint to access
 *
 * @returns {string} endpoint for the enrichment api
 * @private
 * @memberof Enrichment
 */
function getApiEndpoint(config) {
	return getEnvCfg(config.endpoint);
}
/**
 * Fetch wrapper to apply the appropriate headers
 *
 * @param {Object} cohObj an Object of select Cohesion data
 * @param {Object} config Enrichment config
 * @private
 * @memberof Enrichment
 * @returns {Promise} api response or null(when invalid args are passed)
 */
function getApiData(cohObj, cohData, config) {
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Token: isEmpty(config) ? 0 : config.token,
		},
		body: JSON.stringify(cohData),
	};
	options.headers = { ...options.headers, ...cohObj };

	if (typeof cohObj !== 'undefined') {
		const enrEnd = getApiEndpoint(config);
		enrLogger.logInfo('Attempting to get enrichment data from ', enrEnd, ' with options: ', options);
		return fetch(enrEnd, options);
	}
	return Promise.resolve(null);
}
/**
 * Reads from the Enrichment cookie and the variable in the page scope to fill the useg key
 *
 * @returns enrichmentTargeting Object or empty object
 */
function getSavedValues() {
	let enrCookie = '';
	let enrTargObj = {};

	// check for "enrichment cookie" if it exists, use that.
	enrCookie = storage.getCookie('enrichment');
	enrLogger.logInfo('Contents of AdLib enrichment cookie is: ', enrCookie);

	if (typeof enrCookie !== 'undefined' && enrCookie !== '') {
		let enrString = `${enrCookie}`;
		// if the value is from the cookie, add "enrcache" to useg
		if (typeof enrCookie !== 'undefined' && enrCookie !== '') {
			enrString = `${enrString},enrcache`;
		}
		// split the string into an array, dedup, then rejoin
		enrString = enrString
			.split(',')
			.map((s) => s.trim())
			.filter((v, i, a) => typeof v === 'string' && v.length > 0 && a.indexOf(v) === i)
			.join(',');
		enrTargObj = { useg: enrString };
	}
	return enrTargObj;
}
/**
 * Performs all necessary logic for fetching Enrichment data from Monarch.  Called when tagular:ready fires
 *
 * @param {*} done function to call when this is finished can be empty (used by tests)
 * @param {string} [cohesionObj] The Cohesion Object
 * @private
 * @memberof Enrichment
 */
function getEnrichmentData(done, cohesionObj) {
	// save cross-siteId and anonid as a GAM targeting value
	setTargeting({ rv_aid: cohesionObj.webContext.anonymousId, rv_xs: cohesionObj.crossSiteId });

	const cohObj = {
		'Make-Identifier': cohesionObj.crossSiteId,
		'Segment-External-Id': `cross_site_id:${cohesionObj.crossSiteId}`,
		'Anonymous-Id': cohesionObj.webContext.anonymousId,
		'Session-Id': cohesionObj.webContext.sessionId,
		'Instance-Id': cohesionObj.webContext.instanceId,
		'Cross-Site-Id': cohesionObj.crossSiteId,
		'Monarch-Request-Url': cohesionObj.webContext.page.url,
		'Make-Source-Uid': cohesionObj.sourceKey,
	};
	const pageEnrValues = getPageValues();
	const cohData = {
		postId: pageEnrValues.postId, // for PLAM REVSYS-1978
		sesh_count: cohesionObj.session.sessionCount, // for REVSYS-1968 for Differntiated Delivery
		pv_count: pageEnrValues.pv, // for REVSYS-1968 for Differntiated Delivery
	};

	// Get the cookie contents from getSavedValues()
	// if the cookie is available use it.. but still try to load new from Enrichment service to update it REVSYS-1897
	let enrichmentTargetingObj = getSavedValues();

	if (typeof enrichmentTargetingObj.useg === 'undefined' || /^(noxs|noenr|enrerr)$/.test(enrichmentTargetingObj.useg)) {
		enrichmentTargetingObj = { useg: 'noenr' };
	}
	setEnrichmentTargeting(enrichmentTargetingObj);
	const config = getEnrConfig();
	if (isEmpty(config)) {
		enrLogger.logError('Could not retrieve enrichment config');
		const errorObj = new Error('Could not retrieve enrichment config.(enrerr?) ');
		errorReporting.report(errorObj);
		if (typeof enrichmentTargetingObj.useg === 'undefined' || /^(noxs|noenr|enrerr)$/.test(enrichmentTargetingObj.useg)) {
			enrichmentTargetingObj = { useg: 'enrerr' };
		}
		setEnrichmentTargeting(enrichmentTargetingObj);
		done(null, enrichmentTargetingObj);
	} else {
		enrLogger.logInfo('Requesting enrichment for ', cohObj);
		eventEmitter.emit(ENR_FIRED, cohObj, config);
		getApiData(cohObj, cohData, config, done)
			.then((r) => (r ? r.json() : r))
			.then((r) => {
				if (r) {
					enrLogger.logInfo('Enrichment response recieved', r);
					eventEmitter.emit(ENR_RETURNED, r);
					enrichmentTargetingObj = toTargeting(r);
					setEnrichmentTargeting(enrichmentTargetingObj);
				} else {
					enrLogger.logWarn(`Unable to call API. Missing response: cohesionId=${cohObj['Segment-External-Id']}`);
				}
				done(null, enrichmentTargetingObj);
			})
			.catch((err) => {
				enrLogger.logError('Could not retrieve enrichment data', err);
				const errorObj = new Error(`Could not retrieve enrichment data. ${JSON.stringify(err, errorReplacer)}`);
				errorReporting.report(errorObj);
				if (typeof enrichmentTargetingObj.useg === 'undefined' || /^(noxs|noenr|enrerr)$/.test(enrichmentTargetingObj.useg)) {
					enrichmentTargetingObj = { useg: 'enrerr' };
				}
				setEnrichmentTargeting(enrichmentTargetingObj);
				done(err, enrichmentTargetingObj);
			});
	}
}
/**
 * Add onchange function
 * @param {*} done function to call when addOnChange completes
 */
function addOnChange(done) {
	observer.onChange(() => {
		enrLogger.logInfo('Watcher detected cohesion function is available');
		observer.cancel();
		// eslint-disable-next-line no-undef
		cohesion('tagular:ready', () => {
			eventEmitter.emit(TAGULAR_READY);
			enrLogger.logInfo('Tagular:ready fired. (noenr?)');
			// eslint-disable-next-line no-undef
			getEnrichmentData(done, _Cohesion);
		});
	});
}
/**
 * Function called when module initialization is done
 * @param {*} done function to call when this is finished
 */
// eslint-disable-next-line func-names
function getEnrichment(done = function () {}) {
	enrLogger.logInfo('getEnrichment called');
	// if the cookie is available use it..but still try to load new from Enrichment service to update it REVSYS-1897
	let enrichmentTargetingObj = getSavedValues();
	setEnrichmentTargeting(enrichmentTargetingObj);
	if (typeof dom()._Cohesion !== 'undefined' && !isEmpty(dom()._Cohesion)) {
		enrLogger.logInfo('_Cohesion variable found.  Adding callback for tagular:ready');
		dom().cohesion('tagular:ready', () => {
			eventEmitter.emit(TAGULAR_READY);
			enrLogger.logInfo('Tagular:ready fired');
			getEnrichmentData(done, dom()._Cohesion);
		});
	} else {
		enrLogger.logInfo('_Cohesion variable not found.  Adding watcher. (noxs?)');
		if (typeof enrichmentTargetingObj.useg === 'undefined' || /^(noxs|noenr|enrerr)$/.test(enrichmentTargetingObj.useg)) {
			enrichmentTargetingObj = { useg: 'noxs' };
		}
		setEnrichmentTargeting(enrichmentTargetingObj);
		addOnChange(done);
		eventEmitter.on(AUCTION, () => observer.check());
		eventEmitter.on(HEADER_BIDDING_REQUEST, () => observer.check());
		eventEmitter.on('consentChanged', () => setTimeout(observer.check(), 10));
		setTimeout(done(null, enrichmentTargetingObj), 1800);
	}
}

export { getEnrichment, getEnrichmentData, setEnrConfig, toTargeting, getEnrConfig, addContextToOrtb2 };
