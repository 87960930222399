// eslint-disable-next-line import/no-relative-packages
import '../../../prebid/src/prebid.js';
// eslint-disable-next-line import/no-relative-packages
import { getGlobal } from '../../../prebid/src/prebidGlobal.js';
import { requestManager } from '../../requestManager.js';
import { moduleManager } from '../../moduleManager.js';
import defaultsDeep from '../../utilities/helpers/defaultsDeep.js';
import memoize from '../../utilities/helpers/memoize.js';
import { urlQueryAsObject } from '../../utilities/queryParams.js';
import { exposureApi } from '../../exposureApi.js';
import { eventEmitter } from '../../events.js';
import { bbLogger } from '../../utilities/logger.js';
import set from '../../utilities/helpers/set.js';
import get from '../../utilities/helpers/get.js';
import { dom } from '../../global.js';
import { getConfig, setConfig } from '../../config.js';
import { getQueue } from '../../bidbarrel.js';
import { setBidTargeting } from '../../targeting.js';
import CONSTANTS from '../../constants.json';
import { renderScript } from '../../utilities/renderScript.js';

// Prebid Global
const $$PREBID_GLOBAL$$ = getGlobal();

// constants
const {
	MODULES: { GEOLOCATION, PREBID_HEADER_BIDDING, VIDEO, GOOGLE_PUBLISHER_TAG },
	EVENTS: { HEADER_BIDDING_REQUEST, HEADER_BIDDING_RESPONSE }
} = CONSTANTS;

export function runPrebidQueue() {
	if ($$PREBID_GLOBAL$$ && $$PREBID_GLOBAL$$.processQueue) {
		$$PREBID_GLOBAL$$.processQueue();
	}
}

/**
 * Prebid Header Bidding Module
 *
 * exposes `pbjsUnits` off of the root scope
 *
 * @module PrebidHeaderBidding
 * @private
 */
const prebidHeaderBiddingModule = (function pbhb() {
	/**
	 * Url query as object
	 *
	 * @memberof PrebidHeaderBidding
	 * @private
	 */
	const query = urlQueryAsObject();
	/**
	 * Flag for tracking initialization
	 *
	 * @memberof PrebidHeaderBidding
	 * @private
	 */
    let initialized = false;
    /**
     * Property to track allowed sizes for bid requests
     *
     * @memberof PrebidHeaderBidding
     * @private
     */
    let allowedSizes = [];
	/**
	 * Handles setting up pbjs global
	 *
	 * @memberof PrebidHeaderBidding
	 * @private
	 */
	function register() {
		dom().window.pbjs = dom().window.pbjs || {};
		dom().window.pbjs.que = dom().window.pbjs.que || [];
		// adServerRequest.before(updateUnitRegistryHook);
	}
	/**
	 * Sets bid targeting for prebid bids
	 *
	 * @param {Function} next Function to move to the next callback in the hook
	 * @param {BidBarrel~AdUnit[]} unitCollection Unit collection to parse
	 * @memberof PrebidHeaderBidding
	 * @private
	 */
	function setPbjsBidTargeting(next, unitCollection) {
		$$PREBID_GLOBAL$$.que.push(() => {
			if (unitCollection && unitCollection.length) {
				moduleManager.viaModule(GOOGLE_PUBLISHER_TAG, ({gptAction}) => {
					gptAction(() => {
						$$PREBID_GLOBAL$$.setTargetingForGPTAsync(unitCollection.filter(unit => unit.bids && unit.bids.length > 0).map(unit => unit.code));
					})
				})
			}
			next(unitCollection);
		});
	}
/**
	 * Constructs video cache targeting object
	 *
	 * @param {string} bidder bidder code
	 * @param {string} id cache id
	 * @returns {object} k/v targeting object
	 * @memberof PrebidHeaderBidding
	 * @private
	 */
  function constructVideoCacheTargeting(bidder, id){
    const cacheUrlConfigs = getConfig("prebid.cacheUrlConfigs.bidders");
    const prefix = getConfig("prebid.cacheUrlConfigs.prefix");
    const kvObj = defaultsDeep({}, cacheUrlConfigs[bidder] || {}, cacheUrlConfigs.default, {value: id});
    const result = {};
    Object.keys(kvObj).forEach(key => {
      const value = kvObj[key];
      result[`${prefix}${key}`] = value;
    });
    return result;
  }
	/**
	 * Handles applying prebid params(targeting) to video ad url object
	 *
	 * @param {Function} next next hook fn
	 * @param {BidBarrel~AdUnit} unit unit config
	 * @param {Number} index ad index
	 * @param {BidBarrel~VideoUrlOptions} options options config
	 * @memberof PrebidHeaderBidding
	 * @private
	 */
	function applyVideoBidParams(next, unit, index, options){
		const opts = options;
		const existingCustParams = get(opts, "params.cust_params") || {};
		const unitCode = `${unit.code  }--${index}`;
		bbLogger.logInfo("Applying Video params for unit code", unitCode);
		let targeting = $$PREBID_GLOBAL$$.getAdserverTargetingForAdUnitCode(unitCode, {forTargeting: true, match: {video: unit.getVideoSpec(index)}});
		const bidder = get(targeting, "hb_bidder");
		const uuid = get(targeting, "hb_cache_id");
		if(bidder && uuid){
			targeting = defaultsDeep({}, constructVideoCacheTargeting(bidder, uuid), targeting);
			// targeting = omit(targeting, ["hb_uuid", "hb_cache_id"]);
		}
		set(opts, "params.cust_params", defaultsDeep(targeting, existingCustParams))
		next(unit, index, opts)
	}
	/**
	 * Sets the config for the module
	 *
	 * @param {BidBarrel~Configuration} fullBBConfig
	 * @memberof PrebidHeaderBidding
	 * @private
	 */
	function setPrebidConfig(config) {
		$$PREBID_GLOBAL$$.que.push(() => {
			$$PREBID_GLOBAL$$.setConfig(
				defaultsDeep(
					{},
					{
						debug: query.pbjs_debug === 'true'
					},
					config.pbjsConfig
				)
			);
			if (config.bidderSettings) {
				$$PREBID_GLOBAL$$.bidderSettings = config.bidderSettings;
			}
        });
        if(config.allowedSizes){
            allowedSizes = config.allowedSizes.map(size => size.join("x"));
        }
		moduleManager.viaModule(GEOLOCATION, ({ isEnabled, onRegionSet, getRegionCode }) => {
			if (isEnabled && config.enabledRegions) {
				onRegionSet(() => {
					if (config.enabledRegions.indexOf(getRegionCode()) === -1) {
						bbLogger.logInfo('Prebid not enabled for this region, deregistering module.');
						moduleManager.deregister(PREBID_HEADER_BIDDING);
					}
				});
			}
		});
	}
	/**
	 * Initialize method for Module
	 *
	 * @memberof PrebidHeaderBidding
	 * @private
	 */
	function initialize() {
        if(initialized===false ) {
            requestManager.register(PREBID_HEADER_BIDDING);
            setBidTargeting.before(setPbjsBidTargeting);
            moduleManager.viaModule(VIDEO, ({ getVideoUnitParams }) => {
                getVideoUnitParams.before(applyVideoBidParams);
            })
            initialized = true;
            getConfig("prebid", setPrebidConfig);
            setConfig("bidderTimeout", getConfig("timeouts.bidder"));
            getConfig("highFrequencyAdRequest", value => {
                setConfig("bidderTimeout", value ? getConfig("timeouts.hfar") : getConfig("timeouts.bidder"))
            })
            getQueue().push(resolve => {
                runPrebidQueue();
                resolve();
            });
        }
	}


	/**
	 * Method to handle deregistering module. Removes all hooks
	 *
	 * @memberof PrebidHeaderBidding
	 * @private
	 */
	function deregister() {
		if (initialized) {
			setBidTargeting.getHook({ hook: setPbjsBidTargeting }).remove();
			requestManager.deregister(PREBID_HEADER_BIDDING);
		}
	}
	/**
	 * Translate unit size to prebid appropriate data structure
	 *
	 * @param {BidBarrel~AdUnit} unit the ad unit config
	 * @returns {BidBarrel~AdUnit} modified unit config
	 * @private
	 * @memberof PrebidHeaderBidding
	 */
	const adjustSize = memoize((unit) => unit.sizes.filter(size => typeof size !== "string" && allowedSizes.indexOf(size.join("x")) >= 0), unit => unit.code);
	/**
	* @param {BidBarrel~AdUnit} unit
	* @returns {Object} prebid adjusted unit
	* @memberof PrebidHeaderBidding
	* @private
	*/
	function toPbjsUnit(unit){
		const sizes = adjustSize(unit);
		let {code} = unit;
		if (unit.allowedTypes.banner) {
			set(unit, "mediaTypes.banner.sizes", sizes);
		}
		if(unit.allowedTypes.native){
			set(unit, "mediaTypes.native.sendTargetingKeys", false);
			set(unit, "mediaTypes.native.image.required", true);
			set(unit, "mediaTypes.native.image.aspect_ratios", [{ ratio_width:4, ratio_height:3, min_width:400, min_height:300 }]);
			set(unit, "mediaTypes.native.title.required", true);
			set(unit, "mediaTypes.native.title.len", 140);
			set(unit, "mediaTypes.native.sponsoredBy.required", true);
			set(unit, "mediaTypes.native.clickUrl.required", true);
			set(unit, "mediaTypes.native.body.required", false);
		}
		if (unit.allowedTypes.video || unit.isVideo) {
			set(unit, "mediaTypes.video", defaultsDeep(get(unit, "mediaTypes.video") || {}, unit.getLatestVideoSpec()));
			code += `--${unit.getVideoSpecIndex()}`;
		}
		const {mediaTypes, bids, labelAny, labelAll} = unit;

		const pbjsUnit = {
			code,
			mediaTypes,
			bids,
			sizes,
			labelAny,
			labelAll
		};
		return pbjsUnit;
	}
  /**
	 * @param {BidBarrel~AdUnit[]} unitCollection
	 * @returns {BidBarrel~AdUnit[]}
	 * @memberof PrebidHeaderBidding
	 * @private
	 */
	function bidRequest(unitCollection) {
		$$PREBID_GLOBAL$$.que.push(() => {
			$$PREBID_GLOBAL$$.adUnits = [];
			for (let index = 0; index < unitCollection.length; index+=1) {
				const unit = unitCollection[index];
				if(unit.bids && unit.bids.length > 0){
					// if (unit.isVideo || unit.allowedTypes.video) {
					// }
					const reducedUnit = toPbjsUnit(unit);
					$$PREBID_GLOBAL$$.addAdUnits(reducedUnit);
				}
			}
			const auction = $$PREBID_GLOBAL$$.requestBids({
				bidsBackHandler: (bids) => {eventEmitter.emit([HEADER_BIDDING_RESPONSE, `${PREBID_HEADER_BIDDING}.${HEADER_BIDDING_RESPONSE}`], PREBID_HEADER_BIDDING, bids, unitCollection, auction);
					requestManager.done(PREBID_HEADER_BIDDING)
				},
				timeout: getConfig("bidderTimeout")
			});
			eventEmitter.emit([HEADER_BIDDING_REQUEST, `${PREBID_HEADER_BIDDING}.${HEADER_BIDDING_REQUEST}`], PREBID_HEADER_BIDDING, unitCollection, auction);
		});
		return unitCollection;
	}

	exposureApi.rootScope({
		get pbjsUnits() {
			if ($$PREBID_GLOBAL$$.adUnits && $$PREBID_GLOBAL$$.adUnits.length) {
				return $$PREBID_GLOBAL$$.adUnits[0].constructor === Array ? $$PREBID_GLOBAL$$.adUnits[0] : $$PREBID_GLOBAL$$.adUnits;
			}
			return [];
		}
	});
	return {
		name: PREBID_HEADER_BIDDING,
		register,
		initialize,
		deregister,
		bidRequest
	};
})();
// Register module
export const prebidModule = moduleManager.register(prebidHeaderBiddingModule, [GOOGLE_PUBLISHER_TAG], { gate: 'consentGiven' });

// Sharethrough specific script render(per medianet request in REVSYS-1392)
eventEmitter.on("identified.native", (identity) => {
	if(identity.dsp === "sharethrough"){
		renderScript({
			src: "//native.sharethrough.com/assets/sfp.js",
			id: "bb-sharethrough",
			async: true,
			charset: "utf-8"
		})
	}
})

// -----------------------------------
// ADDITIONAL DOCUMENTATION
// -----------------------------------

/**
 * Prebid Bid Objects represent Bid Responses from the third party
 * library Prebid.js
 *
 * {@link http://prebid.org/dev-docs/publisher-api-reference.html#module_pbjs.getBidResponses|Documentation can be found here}
 *
 * @typedef PrebidBid
 * @type {Object}
 */
