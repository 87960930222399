import { bbLogger, debugOn } from '../../utilities/logger';
import { runQueue } from '../../utilities/queue';
import isEmpty from '../../utilities/helpers/isEmpty';
import { exposureApi } from '../../exposureApi';
import { moduleManager } from '../../moduleManager';
import { clientInfo } from '../../services/clientInfo';
import { auction } from '../../bidbarrel';
import { getUnitCollection } from '../../unitManager';
import { getConfig, setConfig } from '../../config';
import { dom } from '../../global';
import { features } from '../../features';
import CONSTANTS from '../../constants.json';
import { errorReporting } from '../../services/errorReporting';
import { renderScript } from '../../utilities/renderScript';
import get from '../../utilities/helpers/get';
import omit from '../../utilities/helpers/omit';
/**
 * Region definitions
 *
 * Speadsheet: https://docs.google.com/spreadsheets/d/1tmGkxKbUHNTPx0qC9GcXSyPCnQwFDRGKSUQ4zwEROaM/edit?ts=5be08cb5#gid=70163422
 * @private
 */

// constants
const { GEOLOCATION } = CONSTANTS.MODULES;

/**
 * Country detection module
 *
 * This module handles all events around checking/parsing region and country codes using the ad library's built in country detection
 *
 * @returns {object} - read only functions along with an initialization function to be used by bid barrel
 * @module GeoModule
 * @private
 */
const geoModule = (function() {
	/**
	 * Variable to cache parsing results to prevent rerunning code
	 *
	 * @memberof GeoModule
	 * @private
	 */
	const cache = {};
	/**
	 * Local variable for tracking for country code
	 *
	 * @memberof GeoModule
	 * @private
	 */
	let countryCode = null;
	/**
	 * Local variable for tracking region code
	 *
	 * @memberof GeoModule
	 * @private
	 */
	let regionCode = null;
	/**
	 * Callback queue for when the country gets set
	 *
	 * @memberof GeoModule
	 * @private
	 */
	let onCountrySetQueue = [];
	/**
	 * Callback queue for when the region gets set
	 *
	 * @memberof GeoModule
	 * @private
	 */
	let onRegionSetQueue = [];
	/**
	 * Config for module
	 *
	 * @memberof GeoModule
	 * @private
	 */
	let config = {};
	/**
	 * Value to track when failsafes are first applied
	 *
	 * @memberof GeoModule
	 * @private
	 */
	let start = null;
	let initialized = false;

	/**
	 * Initialization function that is called in pbjs.cbsi.initialize for GeoModule
	 *
	 * @memberof GeoModule
	 * @private
	 */
	function initialize() {
		if (initialized) return;
		initialized = true;
		bbLogger.logInfo('Initializing Geolocation detection module', config);
		setupRegionQueue();
		if (isOverridden()) {
			applyOverrides();
		} else {
			setupCountryQueue();
			applyFailsafes();
		}
		auction.before(delayAuctionHook);
		getUnitCollection.after(applyGeoBidders);
	}
	/**
	 * Removes registered hooks
	 *
	 * @memberof GeoModule
	 * @private
	 */
	function deregister() {
		auction.getHooks({ hook: delayAuctionHook }).remove();
		getUnitCollection.getHooks({ hook: applyGeoBidders }).remove();
	}
	/**
	 * Function that checks for override variables
	 *
	 * @returns {Boolean} override status
	 * @memberof GeoModule
	 * @private
	 */
	function isOverridden() {
		return config.overrideCountryCode || config.overrideRegionCode;
	}
	/**
	 * Applies overrides to queues
	 * @memberof GeoModule
	 * @private
	 */
	function applyOverrides() {
		if (config.overrideRegionCode) {
			if (getRegionMappings()[config.overrideRegionCode]) {
				setRegionCode(config.overrideRegionCode, true);
			} else {
				bbLogger.logWarn('Region code for override is not mapped (', config.overrideRegionCode, ') using failsafe as fallback:', config.failsafeRegionCode);
				setRegionCode(config.failsafeRegionCode, true);
			}
		}
		if (config.overrideCountryCode) {
			if (getMappedCountries().indexOf(config.overrideCountryCode) >= 0) {
				onCountrySetQueue = { push: callback => callback(config.overrideCountryCode), run: () => false, cancel: () => false };
				setCountryCode(config.overrideCountryCode, true);
			} else {
				bbLogger.logWarn('Country code for override is not mapped (', config.overrideCountryCode, ') using failsafe as fallback:', config.failsafeCountryCode);
				onCountrySetQueue = { push: callback => callback(config.failsafeCountryCode), run: () => false, cancel: () => false };
				setCountryCode(config.failsafeCountryCode, true);
			}
		}
	}
	/**
	 * Applies failsafe timeout for country code
	 * @memberof GeoModule
	 * @private
	 */
	function applyFailsafes() {
		if (config.failsafeCountryCode && config.failsafeTimeout) {
			start = dom().window.performance.now();
			setTimeout(() => {
				if (!countryCode) {
					bbLogger.logInfo('Unable to successfully apply the country code. Using fail safe country code', config.failsafeCountryCode);
					setCountryCode(config.failsafeCountryCode);
				}
			}, config.failsafeTimeout);
		} else {
			bbLogger.logError('Geo Module fail safe values not set correctly:', { failsafeCountryCode: config.failsafeCountryCode, failsafeTimeout: config.failsafeTimeout });
			const errorObj = new Error('Geo Module fail safe values not set correctly:', JSON.stringify({ failsafeCountryCode: config.failsafeCountryCode, failsafeTimeout: config.failsafeTimeout }));
			errorReporting.report(errorObj);
		}
	}
	/**
	 * Sets up country checking queue
	 *
	 * @memberof GeoModule
	 * @private
	 */
	function setupCountryQueue() {
		onCountrySetQueue = runQueue('onCountrySet', onCountrySetQueue, debugOn());
		clientInfo.getCountryCode()
		getConfig("clientInfo.data.country", (cc) => {
			onCountrySetQueue.run(cc.toLowerCase());
		})
		onCountrySetQueue.push(country => {
			bbLogger.logInfo('Successfully applied the location code', country);
			setCountryCode(country);
		});
	}
	/**
	 * Sets up region checking queue
	 *
	 * @memberof GeoModule
	 * @private
	 */
	function setupRegionQueue() {
		onRegionSetQueue = runQueue('onRegionSet', onRegionSetQueue, debugOn());
	}
	/**
	 * Hook that parses bidders object before unit collection is returned
	 *
	 * @param {Function} next
	 * @param {BidBarrel~AdUnit} unitCollection
	 * @memberof GeoModule
	 * @private
	 */
	function applyGeoBidders(next, unitCollection) {
		const parsedUnitCollection = unitCollection.map(unit => {
			unit.bids = parseBidders(unit.bids, unit.code);
			return unit;
		});
		next(parsedUnitCollection);
	}
	/**
	 * Hook to delay auction execution until the region has been set
	 *
	 * @param {Function} next next hook in queue
	 * @param  {...any} args all passed arguments
	 * @memberof GeoModule
	 * @private
	 */
	function delayAuctionHook(next, ...args) {
		if (!regionCode) {
			const timeUntilFailsafesApplied = Math.round(config.failsafeTimeout - (dom().window.performance.now() - start));
			bbLogger.logInfo(
				'Geolocation detection module intercepting auction request. Delaying until region can be determined. Failsafes will be applied in',
				timeUntilFailsafesApplied,
				'milleseconds, code:',
				config.failsafeCountryCode
			);
		}
		onRegionSet(() => {
			next(...args);
		});
	}
	/**
	 * Sets config for geo module
	 *
	 * @memberof GeoModule
	 * @private
	 */
	function register() {
		getConfig("geo", value => {
			config = value;
		})
		renderScript.before(scriptRenderHook);
	}
	/**
	 * Allows the rendering of scripts with filter logic for countries and regions
	 *
	 * @param {Function} next next hook in queue
	 * @param {object} scriptConfig render script config
	 * @memberof GeoModule
	 * @private
	 */
	function scriptRenderHook(next, scriptConfig) {
		const regions = get(scriptConfig, 'filter.regions');
		const countries = get(scriptConfig, 'filter.countries');
		const adjustedScriptConfig = scriptConfig;
		if(regions){
			onRegionSet(region => {
				if(regions.indexOf(region) >= 0){
					next(adjustedScriptConfig);
				}
			})
		} else if(countries) {
			onCountrySet(country => {
				if(countries.indexOf(country) >= 0){
					next(adjustedScriptConfig);
				}
			})
		} else {
			next(adjustedScriptConfig);
		}
	}
	/**
	 * Country code setter
	 *
	 * If it passes the conditional it will also set the region code and cancel the checker interval
	 *
	 * @param {string} code - country code to set the country to
	 * @param {boolean} [override=false] - Whether or not to override the current value
	 * @memberof GeoModule
	 * @private
	 */
	function setCountryCode(code, override = false) {
		if (code && (override || (!override && !countryCode))) {
			countryCode = features.getValue('countryCode') || code;
			bbLogger.logInfo('Country Code Set:', countryCode);
			setRegionCode(getRegionCodeFromDefinitions(), override);
			onCountrySetQueue.run(code);
		}
	}
	/**
	 * Region code setter
	 *
	 * If it passes the conditional it will also cancel the interval and run the callback queue
	 *
	 * @param {string} code - region code to set the region to
	 * @param {boolean} [override=false] - Whether or not to override the current value
	 * @memberof GeoModule
	 * @private
	 */
	function setRegionCode(code, override = false) {
		if (code && (override || (!override && !regionCode))) {
			regionCode = features.getValue('regionCode') || code;
			bbLogger.logInfo('Region Code Set:', regionCode);
			setConfig("geo.regionCode", regionCode);
			onRegionSetQueue.run();
		}
	}
	/**
	 * Gets the current region code from region definition mappings corresponding to the country code
	 *
	 * @returns {string|void} - region code or undefined
	 * @memberof GeoModule
	 * @private
	 */
	function getRegionCodeFromDefinitions() {
		if (countryCode) {
			let region = getRegionFromDefinitions(countryCode);
			if (!region) {
				bbLogger.logWarn('Region code for country is not set (', countryCode, ') using failsafe:', config.failsafeCountryCode);
				region = getRegionFromDefinitions(config.failsafeCountryCode);
			}
			return region;
		} else {
			return undefined;
		}
	}
	/**
	 * Looks through region definitions and finds the provided country code then return the region code
	 *
	 * @param {string} countryCode - country code to look up
	 * @return {string|void} - region code or undefined
	 * @memberof GeoModule
	 * @private
	 */
	function getRegionFromDefinitions(countryCode) {
		const regionDefinitions = getConfig('geo.regionDefinitions');
		for (const regionCode in regionDefinitions) {
			if (Object.prototype.hasOwnProperty.call(regionDefinitions, regionCode)) {
				const countryArray = regionDefinitions[regionCode];
						if (countryArray.indexOf(countryCode) >= 0) {
							return regionCode;
						}
			}
		}
		return undefined;
	}
	/**
	 * Parses unit's bids and applies the appropriate region's bid configs
	 *
	 * @param {any} bidders - unit.bids value
	 * @param {string} unitCode - unit.code value
	 * @returns {array|any} - returns an array of bid configs or the original bidders value
	 * @memberof GeoModule
	 * @private
	 */
	function parseBidders(bidders, unitCode) {
		let rc = getRegionCode(); // region code
		if (cache[unitCode + rc]) return cache[unitCode + rc];
		let result = [];
		if (Array.isArray(bidders)) {
			result = bidders;
		} else if (typeof bidders === 'object' && typeof bidders[regionCode] !== 'undefined') {
			result = bidders[regionCode];
		} else if (typeof bidders === 'object' && typeof bidders[config.failsafeRegionCode] !== 'undefined') {
			bbLogger.logWarn(unitCode, 'Region not configured among bid set.', regionCode, 'Using fallback', config.failsafeRegionCode);
			result = bidders[config.failsafeRegionCode];
			rc = config.failsafeRegionCode;
		} else if (typeof bidders === 'object' && isEmpty(bidders)) {
			result = [];
		} else {
			bbLogger.logWarn(unitCode, 'Unrecognized bids property', bidders);
			result = [];
		}
		cache[unitCode + rc] = result;
		return result;
	}
	/**
	 * Publicly accessible method for retrieving the country code
	 *
	 * Requires module: `geo`
	 *
	 * @returns {string}  country code
	 * @memberof GeoModule
	 * @private
	 * @exposed
	 */
	function getCountryCode() {
		return countryCode;
	}
	/**
	 * Publicly accessible method for retrieving the region code
	 *
	 * Requires module: `geo`
	 *
	 * @returns {string} region code
	 * @memberof GeoModule
	 * @private
	 * @exposed
	 */
	function getRegionCode() {
		return regionCode;
	}
	/**
	 * Publicly accessible method for retrieivng the region definitions
	 *
	 * Requires module: `geo`
	 *
	 * @returns {object} region definitions
	 * @memberof GeoModule
	 * @private
	 * @exposed
	 */
	function getRegionMappings() {
		const regionDefinitions = getConfig('geo.regionDefinitions');
		return regionDefinitions;
	}
	/**
	 * Method to allow callbacks to be ran after the country code has been set
	 *
	 * Requires module: `geo`
	 *
	 * @param {Function} callback
	 * @memberof GeoModule
	 * @private
	 * @exposed
	 */
	function onCountrySet(callback) {
		onCountrySetQueue.push(callback);
	}
	/**
	 * Method to allow callbacks to be ran after the region code has been set
	 *
	 * Requires module: `geo`
	 *
	 * @param {Function} callback
	 * @memberof GeoModule
	 * @private
	 * @exposed
	 */
	function onRegionSet(callback) {
		onRegionSetQueue.push(callback);
	}
	/**
	 * Variable to store mapped countries in(memoize)
	 * @memberof GeoModule
	 * @private
	 */
	let mappedCountries = [];
	/**
	 * Function getter for mapped countries
	 *
	 * Requires module: `geo`
	 *
	 * @memberof GeoModule
	 * @private
	 * @exposed
	 */
	function getMappedCountries() {
		if (!mappedCountries.length) {
			mappedCountries = Object.values(getRegionMappings()).reduce( (a, b) => a.concat(b), []);
		}
		return mappedCountries;
	}
	/**
	 * Variable to store mapped regions in(memoize)
	 * @memberof GeoModule
	 * @private
	 */
	let mappedRegions = [];
	/**
	 * Function getter for mapped regions
	 *
	 * Requires module: `geo`
	 *
	 * @memberof GeoModule
	 * @private
	 * @exposed
	 */
	function getMappedRegions() {
		if (!mappedRegions.length) {
			mappedRegions = Object.keys(getRegionMappings());
		}
		return mappedRegions;
	}

	exposureApi.expose({
		getRegionCode,
		getRegionMappings,
		getMappedCountries,
		getMappedRegions,
		getCountryCode,
		onCountrySet,
		onRegionSet
	});
	/**
	 * Return publicly accessible methods
	 */
	return {
		initialize,
		deregister,
		onRegionSet,
		onCountrySet,
		getRegionCode,
		getCountryCode,
		protected: true,
		register,
		name: GEOLOCATION
	};
})();

export const geo = moduleManager.register(geoModule);
