import template from 'lodash/template';
import isEqual from 'lodash/isEqual';
import { getConfig, setConfig } from '../../config.js';
import { moduleManager } from '../../moduleManager.js';
import { eventEmitter } from '../../events.js';
import context from '../../context.js';
import { storage } from '../../services/storage.js';
import defaultsDeep from '../../utilities/helpers/defaultsDeep.js';
import get from '../../utilities/helpers/get.js';
import { cloneDeep } from '../../utilities/cloneDeep.js';
import { logger } from '../../utilities/logger.js';
// eslint-disable-next-line import/no-relative-packages
import { getGlobal} from '../../../prebid/src/prebidGlobal.js';
// Constants
import CONSTANTS from '../../constants.json';

import { makeSafe } from '../../utilities/safeFunction.js';
// Related Modules
const { CONSENT, PREBID_USER_ID } = CONSTANTS.MODULES;

const uidLogger = logger({ name: 'userId', textColor: '#FFF', bgColor: '#EB5160' });

// Prebid global
const $$PREBID_GLOBAL$$ = getGlobal();

/**
 * Prebid User ID Module for handling the user id modules within Prebid
 *
 * @module PrebidUserId
 * @private
 */
// eslint-disable-next-line func-names
const prebidUserIdBase = (function () {
	/**
	 * Local copy of the prebid.ids configurations
	 *
	 * @type {Object}
	 * @memberof PrebidUserId
	 * @private
	 */
	let config = {};

	/**
	 * Function that checks if an identity config's dependencies are met.
	 *
	 * @param {object} conf
	 * @return {boolean}
	 * @memberof PrebidUserId
	 * @private
	 */
	function meetsDependencies(conf) {
		if (typeof conf.dependencies === 'undefined') return true;

		if (Array.isArray(conf.dependencies)) {
			for (let index = 0; index < conf.dependencies.length; index += 1) {
				const value = conf.dependencies[index];
				if (!value || value === '' || ['undefined', 'false'].indexOf(value) >= 0) {
					return false;
				}
			}
		} else {
			const value = conf.dependencies;
			if (!value || value === '' || ['undefined', 'false'].indexOf(value) >= 0) {
				return false;
			}
		}
		return true;
	}
	/**
	 * Processes removing the user id from the prebid config and it's client side storage
	 *
	 * @param {string|null} [identityKey=null]
	 * @param {boolean} [removeConfig=true]
	 * @return {void}
	 * @memberof PrebidUserId
	 * @private
	 */
	function processRemove(identityKey = null, removeConfig = true) {
		if (!identityKey) {
			const userIdConfigs = config;
      Object.keys(userIdConfigs).forEach(key => {
        if (Object.hasOwnProperty.call(userIdConfigs, key)) {
					processRemove(key);
				}
      });
			return;
		}

		let identityConfig = config[identityKey];
		if (identityConfig.template) {
			const identityConfigCompile = template(JSON.stringify(identityConfig));
			const compiledConfig = JSON.parse(identityConfigCompile({ context }));
			identityConfig = defaultsDeep({}, compiledConfig, identityConfig);
		}
		if (identityConfig.autoDeleteStorage && get(identityConfig, 'userSyncConfig.storage.name')) {
			if (get(identityConfig, 'userSyncConfig.storage.type') === 'cookie') {
				storage.deleteCookie(identityConfig.userSyncConfig.storage.name);
			} else if (get(identityConfig, 'userSyncConfig.storage.type') === 'html5') {
				storage.raw.deleteFromLs(identityConfig.userSyncConfig.storage.name);
			}
			if (identityConfig.onRemove && identityConfig.onRemove.cookies) {
				for (let index = 0; index < identityConfig.onRemove.cookies.length; index += 1) {
					const cookieName = identityConfig.onRemove.cookies[index];
					storage.deleteCookie(cookieName);
				}
			}
			if (identityConfig.onRemove && identityConfig.onRemove.localStorage) {
				for (let index = 0; index < identityConfig.onRemove.localStorage.length; index += 1) {
					const lsKey = identityConfig.onRemove.localStorage[index];
					storage.raw.deleteFromLs(lsKey);
				}
			}
			storage.raw.deleteFromLs(`${identityConfig.userSyncConfig.storage.name}.meta`);
		}
		if (removeConfig) {
			const existingIds = getConfig('prebid.pbjsConfig.userSync.userIds');
			const filteredIds = existingIds ? existingIds.filter((v) => v.name !== identityConfig.userSyncConfig.name) : [];
			setConfig('prebid.pbjsConfig.userSync.userIds', filteredIds);
		}
	}
	/**
	 * Processes adding the user id configs to the prebid configs
	 *
	 * @param {string|null} [identityKey=null]
	 * @return {void}
	 * @memberof PrebidUserId
	 * @private
	 */
	function processAdd(identityKey = null) {
		if (!identityKey) {
			const userIdConfigs = config;
      Object.keys(userIdConfigs).forEach(key => {
        if (Object.hasOwnProperty.call(userIdConfigs, key)) {
					processAdd(key);
				}
      });
			return;
		}
		let identityConfig = config[identityKey];
		if (identityConfig.template && identityConfig.enabled) {
			const identityConfigCompile = template(JSON.stringify(identityConfig));
			const compiledConfig = JSON.parse(identityConfigCompile({ context }));
			identityConfig = defaultsDeep({}, compiledConfig, identityConfig);
		}
		const dependenciesAreMet = meetsDependencies(identityConfig);
		if (identityConfig.enabled && dependenciesAreMet && (getConfig('consent') === true || typeof getConfig('consent') === 'undefined')) {
			if (identityConfig.template) {
				const identityConfigCompile = template(JSON.stringify(identityConfig));
				const compiledConfig = JSON.parse(identityConfigCompile({ context }));
				identityConfig = defaultsDeep({}, compiledConfig, identityConfig);
			}
			if (identityConfig.trackChanges) {
				const cachedValue = storage.getLocalStorage(`${identityConfig.userSyncConfig.storage.name}.meta`);
				if (!isEqual(cachedValue, identityConfig.userSyncConfig)) {
					const shouldRefresh =
						!identityConfig.shouldRefresh ||
						makeSafe(
							() => identityConfig.shouldRefresh(cachedValue, identityConfig.userSyncConfig),
							uidLogger.logError,
							() => false
						);
					if (shouldRefresh) {
						uidLogger.logInfo(`Identity config change detected. Refreshing user id for ${identityKey}`, identityConfig);
						processRemove(identityKey, false);
						storage.setLocalStorage(`${identityConfig.userSyncConfig.storage.name}.meta`, identityConfig.userSyncConfig);
					}
				} else if (!cachedValue) {
					storage.setLocalStorage(`${identityConfig.userSyncConfig.storage.name}.meta`, identityConfig.userSyncConfig);
				}
			}

      $$PREBID_GLOBAL$$.refreshUserIds({submoduleNames: ['liveIntent', 'pubCommon','identityLink', 'criteo','unifiedId']}, () => uidLogger.logInfo("Refresh User ID complete", identityConfig.userSyncConfig));

			const existingIds = getConfig('prebid.pbjsConfig.userSync.userIds');
			const filteredIds = existingIds ? existingIds.filter((v) => v.name !== identityConfig.userSyncConfig.name) : [];
			uidLogger.logInfo('Updating User ID', identityConfig.userSyncConfig);
			setConfig('prebid.pbjsConfig.userSync.userIds', [...filteredIds, identityConfig.userSyncConfig]);
		} else if (!dependenciesAreMet) {
			uidLogger.logInfo('User ID dependencies not met for User ID', identityKey, identityConfig);
		}
	}

	/**
	 * Sets up enable listeners such that we instantly remove the user id from the prebid config as soon as it's disabled
	 *
	 * @memberof PrebidUserId
	 * @private
	 */
	function setupEnableListeners() {
    Object.keys(config).forEach(identityKey => {
      if (Object.hasOwnProperty.call(config, identityKey)) {
        		getConfig(`prebid.ids.${identityKey}.enabled`, (isEnabled) => {
        			if (!isEnabled) {
        				processRemove(identityKey);
        			} else {
        				processAdd(identityKey);
        			}
        		});
        	}
    });
	}

	/**
	 * Handles setting up the module
	 *
	 * @memberof PrebidUserId
	 * @private
	 */
	function register() {
		getConfig('prebid.ids', (userIdConfig) => {
			config = cloneDeep(userIdConfig);
		});
		setupEnableListeners();
	}

	/**
	 * Batch operation to handle consent flag and enable/disable all user ids if consent changes
	 *
	 * @param {boolean} consentGiven
	 * @memberof PrebidUserId
	 * @private
	 */
	function updateUserSyncConfigs(consentGiven) {
		if (consentGiven) {
			uidLogger.logInfo('Applying User ID configs', config);
			processAdd();
		} else {
			uidLogger.logInfo('Removing User ID configs', config);
			processRemove();
		}
	}

	/**
	 * Initialization logic for module
	 *
	 * @memberof PrebidUserId
	 * @private
	 */
	function initialize() {
		eventEmitter.on('consentReady', updateUserSyncConfigs);
		eventEmitter.on('consentChanged', updateUserSyncConfigs);
		const consent = getConfig('consent');
		updateUserSyncConfigs(typeof consent === 'undefined' ? true : consent);
	}

	return {
		name: PREBID_USER_ID,
		register,
		initialize,
	};
})();

// Register module
export const prebidUserId = moduleManager.register(prebidUserIdBase, [CONSENT]);
export default prebidUserId;
