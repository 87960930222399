// eslint-disable-next-line import/no-relative-packages
import { getGlobal } from '../../../prebid/src/prebidGlobal.js';
import { moduleManager } from '../../moduleManager.js';
import { renderScript } from '../../utilities/renderScript.js';
import { logger } from '../../utilities/logger.js';
import { requestManager } from '../../requestManager.js';
import { dom } from '../../global.js';
import { getConfig, setConfig } from '../../config.js';
import { eventEmitter } from '../../events.js';
import { setTargeting } from '../../targeting.js';
import { mergeDeep } from '../../utilities/mergeDeep.js';
import CONSTANTS from '../../constants.json';

const intentLogger = logger({ name: 'intentIQ', textColor: '#FFF', bgColor: '#55ff55' });
const { INTENTIQ } = CONSTANTS.MODULES;
const { BATCH_SLOTS_DEFINED, AUCTION, DISPLAY_CALLED, BID_USED, INTENTIQ_LOADED, INTENTIQ_READY } = CONSTANTS.EVENTS;

// Prebid global
const $$PREBID_GLOBAL$$ = getGlobal();

/**
 * Intent IQ
 *
 * This module adds the Intent IQ identity script to the page
 * and registers the module with the requestManager module to ensure that the Intent IQ
 * ids are available before the ad server request is made.
 *
 * @memberof INTENTIQ
 * @private
 */
const intentIQModuleBase = (function intentIQMod () {
	/**
	 * Configuration object
	 *
	 * @memberof INTENTIQ
	 * @private
	 */
	let config = {};
  let initFired = false;
  let intentLoaded = false;
  let intentIQObj;

  /**
     * Function called when Intent IQ returns IDs
     *
     */
  function intentIQcallback(ret) {
    intentLogger.logInfo('IntentIQ fired. Received ', ret);

    // if ret is a non-empty object, add it to ORTB2 context
    if(typeof ret === 'object' && Object.keys(ret).length > 0 &&  typeof $$PREBID_GLOBAL$$ !== 'undefined') {
      $$PREBID_GLOBAL$$.mergeConfig({ ortb2: { user: { ext: ret } } });
			setConfig('prebid.pbjsConfig.ortb2.user.ext', mergeDeep(getConfig('prebid.pbjsConfig.ortb2.user.ext'), ret));
      intentLogger.logInfo("Added IntentIQ data to ORTB2 context:", $$PREBID_GLOBAL$$.getConfig('ortb2'));
    }

    // report which group AB for intentIQ
    try {
      setTargeting({ intent_iq_group: intentIQObj.intentIqConfig.abTesting.currentTestGroup || "U" });
    } catch (e) {
      setTargeting({ intent_iq_group: "N" });
    }
    eventEmitter.emit(INTENTIQ_READY);
    requestManager.done('intentIQId');
  }

  /**
   * Checks if Intent IQ is loaded
   */
  function checkIntentLoaded() {
    if(typeof dom().window.IntentIqObject === 'function' && intentLoaded === false) {
      intentLoaded = true
      intentLogger.logInfo('IntentIQ loaded');
      eventEmitter.emit(INTENTIQ_LOADED);
      const intentIQparams = config.params;
      intentIQparams.pbjs = $$PREBID_GLOBAL$$;
      intentIQparams.callback = intentIQcallback;

      intentLogger.logInfo('Initializing IntentIqObject', intentIQparams);
      // eslint-disable-next-line no-undef, no-unused-vars
      intentIQObj = new IntentIqObject(intentIQparams);
    } else if(intentLoaded === false){
      intentLogger.logInfo('IntentIQ not loaded yet');
    }
  }

  function reportWin(bid) {
    if(intentLoaded !== false) {
      const reportData = {
        biddingPlatformId: 1, // Prebid
        bidderCode: bid.bidderCode,
        prebidAuctionId: bid.auctionId,
        cpm: bid.cpm,
        currency: bid.currency,
        originalCpm: bid.originalCpm,
        originalCurrency: bid.originalCurrency,
        status: "rendered",
        placementId: bid.adUnitCode,
      };
      intentIQObj.reportExternalWin(reportData);
      intentLogger.logInfo('Reporting win to IntentIQ. reportData: ', reportData);
    }
  }
	/**
	 * Registers the module
	 *
	 * @private
	 * @memberof INTENTIQ
	 */
	function register() {
   getConfig('intentIQ', (newConfig) => {
	 	config = newConfig;
     if(config.enabled === true && initFired===false) {
      initFired = true;

      renderScript({
        src: config.scriptUrl,
        async: true,
        id: 'bb-intentiq',
      });
      requestManager.register('intentIQId');

      dom().window.intentIQOnload = intentIQcallback;

      eventEmitter.on('consentChanged', () => setTimeout(checkIntentLoaded(), 10));
      eventEmitter.on(BATCH_SLOTS_DEFINED, () => checkIntentLoaded());

      eventEmitter.on(DISPLAY_CALLED, () => checkIntentLoaded());
      eventEmitter.on(AUCTION, () => checkIntentLoaded());
      eventEmitter.on(BID_USED, (unitConfig, gptEventObj) => reportWin(unitConfig, gptEventObj)); // does this work for TAM?

    } else if(config.enabled === false ) {
      intentLogger.logInfo('IntentIQ is disabled via config setting.');
    }
	 });
	}
	/**
	 * Initializes module which loads the Intent IQ script
	 *
	 * @private
	 * @memberof INTENTIQ
	 */
	function initialize() {

	}

	return {
		name: INTENTIQ,
		register,
		initialize,
    intentIQOnload: intentIQcallback,
    reportWin,
	};
})();

const intentIQModule = moduleManager.register(intentIQModuleBase, null, { gate: 'consentGiven' });
export default intentIQModule;

/*
Add event tracking for intentIQ

Questions:

When the callback fires, the object passed to the callback is empty, is this expected?
reportExternalWin -
 - Do I fire this off even if intentIQ did not load in time?
 - Does this fire off for each ad that is rendered? need to see if we can catch adx/TAM

*/
