import { getUnits, setSizeMappings as unitSetSizeMappings, defineUnit, setCollapse as unitSetCollapse, getUnitCollection } from '../../unitManager.js';
import { bbLogger, getVerbosityLevel } from '../../utilities/logger.js';
import { eventEmitter } from '../../events.js';
// import { adIdentifier } from '../../services/adIdentifier';
import { getConfig, setConfig } from '../../config.js';
import { setPageTargeting as targetingSetPageTargeting, clearPageTargeting, setUnitTargeting as targetingSetUnitTargeting, clearUnitTargeting, getPageTargeting } from '../../targeting.js';
import { hookedFn } from '../../utilities/hookedFunction.js';
import { runQueue } from '../../utilities/queue.js';
import { cloneDeep } from '../../utilities/cloneDeep.js';
import defaultsDeep from '../../utilities/helpers/defaultsDeep.js';
import pick from '../../utilities/helpers/pick.js';
import { urlQueryAsObject } from '../../utilities/queryParams.js';
import { renderScript } from '../../utilities/renderScript.js';
import { dom } from '../../global.js';
import { getAds, processAdRequest } from '../../bidbarrel.js';
import { moduleManager } from '../../moduleManager.js';
import { readOnlyGetter, hasGetter } from '../../utilities/readOnly.js';
import { exposureApi } from '../../exposureApi.js';
import { errorReporting } from '../../services/errorReporting.js';

// Constant values
import CONSTANTS from '../../constants.json';

// event name constants
const { DFP_EVENTS, AD_LOADED, AD_RENDERED, AD_VIEWABLE, AD_RECEIVED, AD_REQUESTED, AD_VISIBILITY_CHANGED, DISPLAY_CALLED, SLOT_DEFINED, BATCH_SLOTS_DEFINED, INITIALIZE, AD_CALL_FIRED } = CONSTANTS.EVENTS;

/**
 * Google Tag Module
 *
 * This module handles all integrations with the googletag global
 *
 * @module gpt
 * @private
 */
export const gptModuleBase = (function gptMod() {
	/**
	 * Object tracking all googletag slots. The key will be the div id and the value will be the slot object
	 *
	 * @private
	 * @type {Object}
	 * @memberof gpt
	 * @exposed readonly
	 * @exposedAs slots
	 */
	const slots = {};
	/**
	 * Flag tracking whether or not services have been enabled(to prevent redundant calls)
	 *
	 * @type {Boolean}
	 * @memberof gpt
	 * @private
	 */
	let servicesEnabled = false;
	/**
	 * run queue to ensure auction calls are not made pre-init call
	 *
	 * @memberof gpt
	 * @private
	 */
	const afterInit = runQueue('post-init queue', [], getVerbosityLevel() === 5);
    let moduleRegistered=false;
    let defineDone=false;
    	/**
	 * Wrapper method for handling googletag callbacks
	 *
	 * @param {Function} callback function to call in the googletag cmd queue
	 * @memberof gpt
	 * @private
	 */
	function gptAction(callback) {
		dom().window.googletag.cmd.push(() => {
			callback(dom().window.googletag);
		});
	}




  /**
	 * Function getter for getting a specific unit's slot
	 *
	 * @param {String|BidBarrel~AdUnit} unit unit code or unit configuration
	 * @returns {GoogleTagSlot}
	 * @memberof gpt
	 * @private
	 * @exposed
	 */
	function getSlot(unit) {
		const unitCode = typeof unit === 'string' ? unit : unit.code;
		return slots[unitCode];
	}
	/**
	 * Handles calling refresh on ad units
	 *
	 * @param {Function} next next function for hook library
	 * @param {BidBarrel~AdUnit[]} units Unit collection currently being processed
	 * @param {Function} resolve resolve function for promise queue
	 * @memberof gpt
	 * @private
	 */
	function getAdsHook(next, units, resolve = () => false) {
		const hookSlots = [];
		for (let i = 0; i < units.length; i+=1) {
			const unit = units[i];
			const slot = getSlot(unit);
			if(slot){
				hookSlots.push(slot);
			}
		}
    const adSlots = hookSlots.map(slot => ({
      divId: slot.getSlotElementId(),
      targetingMap: slot.getTargetingMap(),
      adUnitPath: slot.getAdUnitPath()
    }));
    // For each element in hookSlots,
    //  - getSlotElementId returns the div id
    //  - getName returns the AdUnitpath
    // - getTargetingMap returns the targeting key value pairs

		if(hookSlots && hookSlots.length > 0){
			gptAction(gpt => { eventEmitter.emit(AD_CALL_FIRED, adSlots); gpt.pubads().refresh(hookSlots)});
		}
		next(units, resolve);
	}

	/**
	 * Event listeners aliasing to BidBarrel event emitter
	 *
	 * @memberof gpt
	 * @private
	 */
	function addDfpEventListeners() {
		gptAction(gpt => {
			const eventsToAlias = [
				[DFP_EVENTS.AD_VIEWABLE, AD_VIEWABLE],
				[DFP_EVENTS.AD_RENDERED, AD_RENDERED],
				[DFP_EVENTS.AD_LOADED, AD_LOADED],
				[DFP_EVENTS.AD_VISIBILITY_CHANGED, AD_VISIBILITY_CHANGED],
				[DFP_EVENTS.AD_REQUESTED, AD_REQUESTED],
				[DFP_EVENTS.AD_RECEIVED, AD_RECEIVED]
			];
			for (let index = 0; index < eventsToAlias.length; index+=1) {
				const [dfpEventName, aliasName] = eventsToAlias[index];
				gpt.pubads().addEventListener(dfpEventName, event => {
					const unitRegistry = getUnits();
					const unitElementId = event.slot.getSlotElementId();
					if(unitRegistry[unitElementId]){
						eventEmitter.emit([dfpEventName, aliasName, `${unitElementId}.${dfpEventName}`, `${unitElementId}.${aliasName}`], unitRegistry[unitElementId], event);
					}
				});
			}
			// adIdentifier.initialize();
		});
	}

	/**
	 * Handles displaying an ad unit or set of ad units
	 *
	 * @param {BidBarrel~AdUnit|BidBarrl~AdUnit[]} units Unit collection to display
	 * @memberof gpt
	 * @private
	 */
	function displayUnit(units) {
		if (Array.isArray(units)) {
			for (let index = 0; index < units.length; index+=1) {
				const unit = units[index];
				displayUnit(unit); // MLS why is this recursive?
			}
			return;
		}
		gptAction(gpt => {
			const unit = units;
			if (unit.displayed) return;
			const el = dom().window.document.getElementById(unit.code);
			if(el){
				gpt.display(el);
				unit.displayed = true;
				eventEmitter.emit([DISPLAY_CALLED,`${unit.code}.${DISPLAY_CALLED}`], unit, slots[unit.code])
			} else {
				bbLogger.logError("Cannot find element for unit. Please ensure ad element is on the page prior to ad request attempts", unit);
				const errorObj = new Error(`Cannot find element for unit. Please ensure ad element is on the page prior to ad request attempts ${unit}.`);
				errorReporting.report(errorObj);
			}
		});
	}
	/**
	 * Handles setting the collapse setting on ad units
	 *
	 * @param {String|String[]} code unit codes or a single unit code
	 * @memberof gpt
	 * @private
	 */
	function setCollapse(code) {
		// eslint-disable-next-line no-unused-vars
		gptAction(gpt => {
			if (Array.isArray(code)) {
				for (let index = 0; index < code.length; index+=1) {
					const currentCode = code[index];
					setCollapse(currentCode);
				}
				return;
			}
			const unitRegistry = getUnits();
			const unitConfigValue = unitRegistry[code].collapseEmptyDiv;
			const globalConfigValue = getConfig("collapseEmptyDivs");
			if ((typeof unitConfigValue !== 'undefined' || typeof globalConfigValue !== "undefined") && slots[code]) {
				let value = unitConfigValue || globalConfigValue;
				if(unitRegistry[code].isLazyLoaded() && Array.isArray(value) && value[0] === true && value[1] === true){
					bbLogger.logInfo("Disabling pre-ad request collapsing. Cannot lazy load and collapse empty div before the ad request", unitRegistry[code]);
					value = true;
				}
				if (Array.isArray(value)) {
					slots[code].setCollapseEmptyDiv(...value);
				} else {
					slots[code].setCollapseEmptyDiv(value);
				}
			}
		});
	}
  /**
	 * Sets the size mapping on a unit
	 *
	 * @param {String} unitCode
	 * @param {BidBarrel~SizeMapping} mappings
	 * @memberof gpt
	 * @private
	 */
	function setSizeMappings(unitCode, mappings){
		if(!mappings) return;
		gptAction(gpt => {
			const config = getConfig();
			const unitRegistry = getUnits();
			if (slots[unitCode] && !unitRegistry[unitCode].servicesApplied) {
				bbLogger.atVerbosity(3).logInfo(`Applying Size Mapping to slot ${unitCode}`, unitRegistry[unitCode].sizeMappings);
				const mapping = gpt.sizeMapping();
				const adjuster = config.adjustSlotDefinition && config.adjustSlotDefinition[unitCode] ? config.adjustSlotDefinition[unitCode] : unit => unit;
				const adjustedUnit = cloneDeep(unitRegistry[unitCode]);
				for (let i = 0; i < unitRegistry[unitCode].sizeMappings.length; i+=1) {
					const applicableMapping = unitRegistry[unitCode].sizeMappings[i];
					adjustedUnit.sizes = applicableMapping.sizes;
					const { sizes } = adjuster(adjustedUnit);
					mapping.addSize(applicableMapping.viewport, sizes);
				}
				slots[unitCode].defineSizeMapping(mapping.build());
			} else if (slots[unitCode] && unitRegistry[unitCode].servicesApplied) {
				bbLogger.atVerbosity(3).logWarn('Unable to apply size mapping, slot has already been defined', unitCode);
			}
		});
	}
 	/**
	 * Central function for setting targeting for page
	 *
	 * @param {String} key targeting key
	 * @param {String} value targeting value
	 * @memberof gpt
	 * @private
	 */
	function setPageTargeting(key, value){
		gptAction(gpt => {
			bbLogger.logInfo(`Setting targeting for page: ${key}=${value}`)
			gpt.pubads().setTargeting(key, `${value}`);
		});
	}
	/**
	 * Central function for setting targeting for a specific slot
	 *
	 * @param {String} code unit code for slot lookup
	 * @param {String} key targeting key
	 * @param {String|number} value targeting value
	 * @memberof gpt
	 * @method
	 * @private
	 */
	const setUnitTargeting = hookedFn('sync', (code, key, value) => {
		// eslint-disable-next-line no-unused-vars
		gptAction(gpt => {
			if(slots[code]){
				bbLogger.logInfo(`Setting targeting on slot for ${code}: ${key}=${value}`)
				slots[code].setTargeting(key, `${  value}`);
			}
		})
	})
	/**
	 * Sets a targeting object for the page or a specific unit
	 *
	 * @param {Object} targeting
	 * @param {String|null} code
	 * @private
	 * @memberof gpt
	 */
	function setTargeting(targeting, code = null){
		// eslint-disable-next-line no-unused-vars
		gptAction(gpt => {
			// eslint-disable-next-line no-restricted-syntax
			for (const key in targeting) {
				if (Object.prototype.hasOwnProperty.call(targeting, key)) {
					const value = targeting[key];
					if(!code){
						setPageTargeting(key, value);
					} else if(slots[code]) {
						setUnitTargeting(code, key, value);
					} else {
						bbLogger.logWarn('Slot not available for unit, unable to set targeting', code, targeting);
					}
				}
			}
		})
	}
  /**
	 * This gets the current targeting object for the ad unit slot
	 *
	 * @param {String|String[]|GoogleTagSlot|GoogleTagSlot[]} slotOrCode slot or unit code to lookup
	 * @param {RegExp|null} [exclusionRegex=null] regex to exclude certain keys
	 * @memberof gpt
	 * @private
	 */
	function getSlotTargeting(slotOrCode) {
		if (slotOrCode.constructor === Array) {
			const result = [];
			for (let index = 0; index < slotOrCode.length; index+=1) {
				const currentSlotOrCode = slotOrCode[index];
				result.push(getSlotTargeting(currentSlotOrCode));
			}
			return result;
		}
		const slot = typeof slotOrCode === 'string' ? getSlot(slotOrCode) : slotOrCode;
		if (!slot) {
            // this prevents the error caused by a race condition
            // between making getSlotTargeting available and ads defined.
            // it doesn't fix the issue.. just prevents the error message.
            if(moduleRegistered===true && defineDone===true) {
                bbLogger.logError('Slot not defined', slotOrCode);
                const errorObj = new Error(`Slot not defined ${slotOrCode}.`);
                errorReporting.report(errorObj);
            }
			return {};
		}
		const slotTargetingMap = slot.getTargetingMap();
		const resultObj = {};

		// eslint-disable-next-line no-restricted-syntax
		for (const key in slotTargetingMap) {
			if (Object.prototype.hasOwnProperty.call(slotTargetingMap, key)) {
				const value = slotTargetingMap[key];
				resultObj[key] = `${  value}`;

			}
		}
		return resultObj;
	}


/**
	 * Defines slots in the registery with a given array of units
	 *
	 * @param {BidBarrel~AdUnit[]} units
	 * @memberof gpt
	 * @private
	 */
function defineSlots(units) {
  gptAction(gpt => {
    const adjustedUnits = [];
    if (units.length > 0) {
      bbLogger.atVerbosity(1).logInfo('Defining slots', units);
      const config = getConfig();
      const unitRegistry = getUnits();
      const dfpPath = getConfig('dfpPathObj').string;
      for (let i = 0; i < units.length; i+=1) {
        const unit = units[i];
        if (!slots[unit.code]) {
          let adjustedUnit = cloneDeep(unit);
          if (config.adjustSlotDefinition && config.adjustSlotDefinition[adjustedUnit.code]) {
            adjustedUnit = config.adjustSlotDefinition[adjustedUnit.code](adjustedUnit);
            bbLogger.atVerbosity(2).logInfo('Adjusting on slot definition', unit, 'to', adjustedUnit);
          }
          bbLogger.logInfo(`Defining slot path=${dfpPath} elementId=${adjustedUnit.code} sizes=${adjustedUnit.sizes ? adjustedUnit.sizes.map(s => Array.isArray(s) ? s.join("x"):s).join(",") : 'none'}`);
          if (adjustedUnit.outOfPage) {
            slots[adjustedUnit.code] = gpt.defineOutOfPageSlot(dfpPath, adjustedUnit.code);
          } else if (adjustedUnit.maxSize) {
            if (adjustedUnit.minSize) {
              slots[adjustedUnit.code] = gpt.defineSlot(dfpPath, {
                'fixed': adjustedUnit.sizes,
                'min': adjustedUnit.minSize,
                'max': adjustedUnit.maxSize,
              }, adjustedUnit.code);
            } else {
              slots[adjustedUnit.code] = gpt.defineSlot(dfpPath, {
                'fixed': adjustedUnit.sizes,
                'max': adjustedUnit.maxSize,
              }, adjustedUnit.code);
            }
          } else {
            slots[adjustedUnit.code] = gpt.defineSlot(dfpPath, adjustedUnit.sizes, adjustedUnit.code);
          }
          setCollapse(adjustedUnit.code);
          slots[adjustedUnit.code].addService(gpt.pubads());
          setSizeMappings(adjustedUnit.code, adjustedUnit.sizeMappings);
          unitRegistry[adjustedUnit.code].servicesApplied = true;
          setTargeting(adjustedUnit.targeting, adjustedUnit.code);
          adjustedUnits.push(adjustedUnit);
          eventEmitter.emit([SLOT_DEFINED, `${adjustedUnit.code}.${SLOT_DEFINED}`], adjustedUnit, slots[adjustedUnit.code]);
        } else {
          const keys = Object.keys(unit.targeting);
          const slotTargeting = pick(getSlotTargeting(unit.code), keys);
          if (JSON.stringify(unit.targeting) !== JSON.stringify(slotTargeting)) {
            setTargeting(unit.targeting, unit.code);
          }
        }
      }
      eventEmitter.emit(BATCH_SLOTS_DEFINED, adjustedUnits, adjustedUnits.map(getSlot));
    }
  });
}

	/**
	 * enables googletag services
	 *
	 * @memberof gpt
	 * @method
	 * @private
	 */
	const enableGoogletagServices = hookedFn('sync', () => {
		gptAction(gpt => {
			gpt.enableServices();
		});
	});
	/**
	 * Enables services for DFP
	 *
	 * @memberof gpt
	 * @private
	 */
	function enableServices() {
		if (!servicesEnabled) {
			bbLogger.atVerbosity(3).logInfo('Enabling services');
			servicesEnabled = true;
			gptAction(gpt => {
                bbLogger.atVerbosity(3).logInfo('Enabling GPT SRA');
				gpt.pubads().enableSingleRequest();
				enableGoogletagServices(gpt);
			});
		}
	}

  /**
	 * Single function call for handling defining and displaying ad slots
	 *
	 * @param {BidBarrel~AdUnit[]} units
	 * @memberof gpt
	 * @method
	 * @private
	 */
	const defineAndDisplay = hookedFn('sync', (units) => {
		afterInit.push(() => {
			defineSlots(units);
			displayUnit(units);
			enableServices();
		});
	})
	/**
	 * Hook method to handle defining and displaying ad units prior to the ad request.
	 *
	 * @param {Function} next next function for hook library
	 * @param {BidBarrel~AdUnit[]} unitCollection Currently processing ad unit configs
	 * @memberof gpt
	 * @private
	 */
	function processAdRequestHook(next, unitCollection) {
		defineAndDisplay(unitCollection);
		// eslint-disable-next-line no-unused-vars
		gptAction(gpt => {
			next(unitCollection);
		})
	}

  /**
	 * Parses a string or object in order to apply the appropriate
	 * values to for configuring the dfp path
	 *
	 * @param {String|Object} existingValue existing dfp path value
	 * @memberof gpt
	 * @private
	 * @returns {Object}
	 */
	function parseDfpPath(existingValue) {
		let resultValue = existingValue;
		const DEVICES = getConfig('dfpPathDevices');
		if (typeof resultValue === 'string') {
			const parts = resultValue.split('/');
			const deviceRegionProperty = parts[2].split('-');
			const regexResult = deviceRegionProperty[0].match(/^(app|m)/);
			const device = regexResult ? regexResult[0] : '';
			resultValue = defaultsDeep(
				{
					string: resultValue,
					network: parts[1],
					property: deviceRegionProperty[1],
					device: typeof DEVICES[device.toLowerCase()] !== 'undefined' ? DEVICES[device.toLowerCase()] : device,
					region: deviceRegionProperty[0].substring(device.length),
					pagePath: parts.slice(3).join('/')
				},
				getConfig('dfpPathObj')
			);
		} else if (typeof resultValue === 'object') {
			resultValue = defaultsDeep({}, resultValue, getConfig('dfpPathObj'));
			if (resultValue.device && typeof DEVICES[resultValue.device.toLowerCase()] !== 'undefined') {
				resultValue.device = DEVICES[resultValue.device.toLowerCase()];
			}
			if (resultValue.isMobile) {
				resultValue.device = 'm';
			}
			if (resultValue.isApp) {
				resultValue.device = 'app';
			}
			if (resultValue.isDesktop) {
				resultValue.device = '';
			}
		}
		return resultValue;
	}


	/**
	 * The dfp path object setter and getter that
	 * allows for dynamic generation of the dfp path
	 *
	 * @param {String|Object} value DFP Path value string or object to construct the string
	 * @memberof gpt
	 * @private
	 */
	function setDfpPathObj(value) {
		const result = parseDfpPath(value);
		const query = urlQueryAsObject();
		if (query.adNetwork) {
			result.network = query.adNetwork;
		}
		if (query.adRegion) {
			result.region = query.adRegion;
		}

		result.string = `/${result.network}/${result.device}${result.region}-${result.property}`;
		if (result.pagePath) {
			result.string += `/${result.pagePath}`;
		}
		return result;
	}
	/**
	 * Sets globals on the page and renders the googletag script
	 *
	 * @memberof gpt
	 * @private
	 */
	function setGlobals() {
		dom().window.googletag = dom().window.googletag || {};
		dom().window.googletag.cmd = dom().window.googletag.cmd || [];
		setTimeout(() => {
			renderScript({
				id: 'googletag-script',
				src: '//securepubads.g.doubleclick.net/tag/js/gpt.js',
				async: true
			});
		}, 0)
	}




	/**
	 * API method for clearing an ad slot
	 *
	 * @param {string[]} unitCodes Array of div ids / unit codes
	 * @memberof gpt
	 * @private
	 * @exposed
	 */
	function clearSlots(unitCodes) {
		afterInit.push(() => {
			gptAction(gpt => {
				bbLogger.atVerbosity(3).logInfo('Clearing slots', unitCodes);
				const slotCollection = Array.isArray(unitCodes) ? unitCodes.map(slotCode => getSlot(slotCode)) : getSlot(unitCodes);
				gpt.pubads().clear(slotCollection);
			});
		});
	}
	/**
	 * API method for destroying an ad slot
	 *
	 * @param {string[]} unitCodes array of div ids / unit codes
	 * @memberof gpt
	 * @private
	 * @exposed
	 */
	function destroySlots(unitCodes) {
		gptAction(gpt => {
			const unitCollection = getUnitCollection(unitCodes);
			bbLogger.atVerbosity(3).logInfo('Destroying slots', unitCollection);
			let slotCollection = [];
			slotCollection = unitCollection.constructor === Array ? unitCollection.map(getSlot) : getSlot(unitCollection);
			gpt.destroySlots(slotCollection);

			const unitRegistry = getUnits();
			for (let i = 0; i < unitCollection.length; i+=1) {
				const unitCode = unitCollection[i].code;
				if (slots[unitCode]) {
					delete slots[unitCode];
				}
				if (unitRegistry[unitCode]) {
					unitRegistry[unitCode].displayed = false;
					unitRegistry[unitCode].servicesApplied = false;
				}
			}
		});
	}
	/**
	 * Destroys all slots registered through BidBarrel
	 *
	 * @memberof gpt
	 * @private
	 * @exposed
	 */
	function destroyAllSlots() {
		destroySlots(Object.keys(slots));
	}

	/**
	 * Handles setting page level targeting key/values for googletag
	 *
	 * @param {Function} next next function for hook library
	 * @param {String} key targeting key
	 * @param {*} value targeting value
	 * @memberof gpt
	 * @private
	 */
	function setPageTargetingHook(next, key, value) {
		setPageTargeting(key, value)
		next(key, value);
	}
	/**
	 * Sets unit targeting on googletag
	 *
	 * @param {Function} next next function for hook library
	 * @param {String} code Ad unit code
	 * @param {String} key targeting key
	 * @param {*} value targeting value
	 * @param {Boolean} templates Whether or not to additionally apply this change to the unit templates object
	 * @memberof gpt
	 * @private
	 */
	function setUnitTargetingHook(next, code, key, value, templates) {
		setUnitTargeting(code, key, value);
		next(code, key, value, templates);
	}
	/**
	 * Hook for clearing page targeting
	 *
	 * @param {Function} next function for hook library
	 * @param {String[]} keys string of keys set on page targeting
	 * @memberof gpt
	 * @private
	 */
	function clearPageTargetingHook(next, keys) {
		gptAction(gpt => {
			gpt.pubads().clearTargeting(keys);
		});
		next(keys);
	}
	/**
	 * Hook for clearing unit level targeting
	 *
	 * @param {Function} next function for hook library
	 * @param {String} code unit code to clear targeting on
	 * @param {String[]} keys targeting keys
	 * @param {Object} registry registry object to use for unit lookup
	 * @memberof gpt
	 * @private
	 */
	function clearUnitTargetingHook(next, code, keys, registry) {
		// eslint-disable-next-line no-unused-vars
		gptAction(gpt => {
			if (slots[code]) {
				for (let index = 0; index < keys.length; index+=1) {
					const key = keys[index];
					slots[code].clearTargeting(key);
				}
			}
		});
		next(code, keys, registry);
	}


	/**
	 * Hook for setting the collapse empty div setting on specific units in googletag
	 *
	 * @param {Function} next function for hooks library
	 * @param {String|String[]} code code or set of codes to apply the change to
	 * @param {Boolean|Boolean[]} value values passed to setCollapseEmptyDiv
	 * @memberof gpt
	 * @private
	 */
	function setCollapseHook(next, code, value) {
		setCollapse(code);
		next(code, value);
	}
  /**
	 * Hook to handle setting the size mappings on a specific unit
	 *
	 * @param {Function} next function for hooks library
	 * @param {String|String[]} unitCode Unit code or array of unit codes
	 * @param {BidBarrel~SizeMapping} mappings Size mappings to apply to the unit(s)
	 * @memberof gpt
	 * @private
	 */
	function setSizeMappingsHook(next, unitCode, mappings) {
		// Skip if recursion will occur. See setSizeMappings function.
		if (!Array.isArray(unitCode)) {
			const unitRegistry = getUnits();
			if (slots[unitCode] && unitRegistry[unitCode].servicesApplied) {
				bbLogger.atVerbosity(3).logWarn('Unable to apply size mapping, slot has already been defined', unitCode);
			}
		}
		next(unitCode, mappings);
	}

	/**
	 * Function getter for the slots object
	 *
	 * @returns {Object}
	 * @memberof gpt
	 * @private
	 */
	function getSlots() {
		return slots;
	}
	/**
	 * Gets the dfp path string
	 *
	 * @return {String} dfpPath
	 * @memberof gpt
	 * @private
	 * @exposed readonly
	 * @exposedAs dfpPath
	 */
	function getDfpPath(){
		return getConfig("dfpPathObj.string")
	}
	/**
	 * Get the dfp path object. Which is a parsed object of all properties included in the DFP Path string
	 *
	 * @return {Object} dfpPathObj
	 * @memberof gpt
	 * @private
	 * @exposed readonly
	 * @exposedAs dfpPathObj
	 */
	function getDfpPathObj(){
		return getConfig("dfpPathObj");
	}
  /**
	 * Handles defining api methods on the BidBarrel ad unit object
	 *
	 * @param {Function} next next function for hook library
	 * @param {BidBarrel~AdUnit} unit ad unit currently being defined
	 * @memberof gpt
	 * @private
	 */
	function defineUnitHook(next, unit, designation) {
		// eslint-disable-next-line no-param-reassign
		unit.display = () => displayUnit(unit);
		if (!unit.slot && !hasGetter(unit, 'slot')) {
			readOnlyGetter(unit, 'slot', () => getSlot(unit));
		}
		if(!unit.slotTargeting && !hasGetter(unit, 'slotTargeting')){
			readOnlyGetter(unit, 'slotTargeting', () => getSlotTargeting(unit.code));
		}
		if(!unit.generateImpressionId){
			// eslint-disable-next-line no-param-reassign
			unit.generateImpressionId = (targetingKeys) => {
				const targeting = {...getPageTargeting(),...getSlotTargeting(unit.code)};
				const keys = targetingKeys;

				// eslint-disable-next-line no-unused-vars
				return keys.reduce((result, key, index) => {
					if(targeting[key]){
						result.push(`${key}=${targeting[key]}`);
					}
					return result;
				},[]).join("|")
			}
		}
		next(unit, designation);
	}
  /**
	 * Handles application all necessary hooks for the gpt to properly accomplish it's processes
	 *
	 * @memberof gpt
	 * @private
	 */
	function addHooks() {
		targetingSetPageTargeting.before(setPageTargetingHook);
		clearPageTargeting.before(clearPageTargetingHook);
		targetingSetUnitTargeting.before(setUnitTargetingHook);
		clearUnitTargeting.before(clearUnitTargetingHook);
		unitSetSizeMappings.before(setSizeMappingsHook);
		unitSetCollapse.before(setCollapseHook);
		// displayUnit.after(displayUnitHook);
		defineUnit.after(defineUnitHook);
		processAdRequest.before(processAdRequestHook);
		getAds.before(getAdsHook);
	}
  /**
	 * Register method for module
	 *
	 * @memberof gpt
	 * @private
	 */
	function register() {
		setGlobals();
		addDfpEventListeners();
		addHooks();
		getConfig('dfpPath', dfpPathVal => {
			const newValue = setDfpPathObj(dfpPathVal);
			setConfig('dfpPathObj', newValue);
		});
		gptAction(gpt => {
			gpt.pubads().disableInitialLoad();
		});
		eventEmitter.on(INITIALIZE, () => afterInit.run());
        eventEmitter.on(BATCH_SLOTS_DEFINED, () => { defineDone=true;});
        moduleRegistered=true;
	}

	exposureApi.rootScope({
		clearSlots,
		destroySlots,
		destroyAllSlots,
		getSlot
	})
	exposureApi.rootScopeGetters({
		slots: () => getSlots(),
		dfpPath: getDfpPath,
		dfpPathObj: getDfpPathObj,
		query: () => urlQueryAsObject()
	})

	return {
		register,
		enableGoogletagServices,
		getSlots,
		getSlot: code => slots[code],
		setUnitTargeting,
		defineAndDisplay,
		gptAction,
		getSlotTargeting,
		clearSlots,
		destroyAllSlots,
		destroySlots,
		name: CONSTANTS.MODULES.GOOGLE_PUBLISHER_TAG
	};
})();

export const gptModule = moduleManager.register(gptModuleBase, null);

/**
 * Slots represent Ad Slots created by Bid Barrel and {@link https://developers.google.com/doubleclick-gpt/reference|Google Tag} using {@link #BidBarrel~AdUnit|BidBarrel~AdUnit}s
 * and a provided DFP Path.
 *
 * {@link https://developers.google.com/doubleclick-gpt/reference#googletagslot|Documentation can be found here}
 *
 * @typedef GoogleTagSlot
 * @type Function
 *
 */
