
const distinctionRules = {
    seconds: {
        isMatch: (distinction) => ['s', 'sec', 'seconds', 'secs'].indexOf(distinction) >= 0,
        getMsAmount: amount => amount * 1000
    },
    minutes: {
        isMatch: (distinction) => ['m', 'min', 'minutes', 'mins'].indexOf(distinction) >= 0,
        getMsAmount: amount => amount * 60 * 1000
    },
    hours: {
        isMatch: (distinction) => ['h', 'hr', 'hours', 'hrs'].indexOf(distinction) >= 0,
        getMsAmount: amount => amount * 60 * 60 * 1000
    },
    days: {
        isMatch: (distinction) => ['d', 'day', 'days'].indexOf(distinction) >= 0,
        getMsAmount: amount => amount * 24 * 60 * 60 * 1000
    },
    weeks: {
        isMatch: (distinction) => ['wk', 'wks', 'w', 'week', 'weeks'].indexOf(distinction) >= 0,
        getMsAmount: amount => amount * 7 * 24 * 60 * 60 * 1000
    },
    months: {
        isMatch: (distinction) => ['M', 'mo', 'mos', 'Mo', 'Mos', 'month', 'months'].indexOf(distinction) >= 0,
        getMsAmount: amount => amount * 30 * 7 * 24 * 60 * 60 * 1000
    },
    years: {
        isMatch: (distinction) => ['y', 'yr', 'years', 'year', 'yrs'].indexOf(distinction) >= 0,
        getMsAmount: amount => amount * 12 * 30 * 7 * 24 * 60 * 60 * 1000
    },
    midnight: {
        isMatch: (distinction) => distinction === "midnight",
        absolute: true,
        getMsAmount: amount => getMidnight().getTime()
    },
    midnightEst: {
        isMatch: (distinction) => distinction === "midnightEst",
        absolute: true,
        getMsAmount: amount => getMidnight(-4).getTime()

    }
}

export function getDate(distinction){
    if(typeof distinction.getTime !== "undefined"){
        return distinction;
    } else {
        return new Date(getTimestamp(distinction));
    }
}

export function getTimestamp(distinction){
    if(typeof distinction.getTime !== "undefined"){
        return distinction.getTime();
    } else if(typeof distinction === "string"){
        return translateStringExpiration(distinction);
    } else if(typeof distinction === "number" && distinction >= 1500000000000){
        return distinction;
    } else if(typeof distinction === "number"){
        return new Date().getTime() + distinction;
    }
}

export function getExpiration(options){
    const now = new Date().getTime();
    const result = options.expires || options || {};
    let expiresTimestamp = result.expiresTimestamp;
    if(!expiresTimestamp){
        expiresTimestamp = getTimestamp(options.at || options.expires || options);
        result.expiresTimestamp = expiresTimestamp;
    }
    result.fromNowMs = expiresTimestamp - now;
    result.isExpired = result.fromNowMs <= 0;
    return result;
}

export function getTimezoneHourOffset(){
    const now = new Date();
    return now.getUTCHours() < now.getHours() ? now.getHours() - (now.getUTCHours() + 24) : now.getHours() - now.getUTCHours();
}

export function getMidnight(tzOffset = getTimezoneHourOffset()){
    const midnight = new Date();
    if(tzOffset < 0){
        midnight.setUTCHours(Math.abs(tzOffset), 0, 0, 0);
    } else {
        midnight.setUTCHours(tzOffset, 0, 0, 0);
    }

    midnight.setDate(midnight.getDate() + 1);

    return midnight;
}

function translateStringExpiration(value){
    const amounts = value.match(/(\d+)/);
    const distinctions = value.match(/(\D+)/gm);
    let timestamp = new Date().getTime();
    let absDecrement = 0;
    for (let index = 0; index < distinctions.length; index++) {
        const distinction = distinctions[index];
        const itIx = index - absDecrement;
        for (const key in distinctionRules) {
            if (Object.prototype.hasOwnProperty.call(distinctionRules, key)) {
                const rule = distinctionRules[key];
                if(rule.isMatch(distinction)){
                    if(rule.absolute){
                        timestamp = rule.getMsAmount(amounts && amounts[itIx] ? parseInt(amounts[itIx]) : null);
                        absDecrement++;
                    } else {
                        timestamp += rule.getMsAmount(amounts && amounts[itIx] ? parseInt(amounts[itIx]) : null);
                    }
                    break;
                }
            }
        }
    }
    return timestamp;
}