import isEmpty from '../utilities/helpers/isEmpty';
import { exposureApi } from '../exposureApi';
import { getConfig, setConfig } from '../config';
import { features } from '../features';
import { cloneDeep } from '../utilities/cloneDeep';
import { bbLogger } from '../utilities/logger';
import { api } from './api';
import { errorReporting } from '../services/errorReporting';
import errorReplacer from '../utilities/helpers/errorReplacer';

/**
 * Client Info Service
 * 
 * This service handles pulling in various information based on the client side user.
 * 
 * @module clientInfo
 * @private
 */
export const clientInfo = (function() {
	/**
	 * Variable tracking inflight requests
	 * 
	 * @private
	 * @memberof clientInfo
	 */
	let inFlightReq;

	/**
	 * Handles setting up the cancellation logic for an inflight request.
	 * 
	 * @private
	 * @memberof clientInfo
	 */
	function initialize(){
		getConfig("clientInfo.data.country", () => {
			if(inFlightReq && inFlightReq.geoOnly){
				bbLogger.logInfo("Aborting Inflight ClientInfo request");
				inFlightReq.abort();
			}
		})
	}

	/**
	 * Function to get the client side user's information
	 * 
	 * @memberof clientInfo
	 * @returns {Promise<ClientInformation>} client side user's information
	 * @private
	 * @method
	 * @exposed
	 * @exposedAs getClientInfo
	 */
	function getInfo(geoOnly = false) {
		return new Promise((resolve, reject) => {
			const info = getConfig("clientInfo.data");
			if (!isEmpty(info)) {
				resolve(cloneDeep(info));
			} else {
				const unsubscribe = getConfig("clientInfo.data", data => {
					unsubscribe();
					resolve(data);
				})
			}
			getInfoFromApi(geoOnly)
				.then(result => {
					inFlightReq = null;
					return result
				})
				.then(result => {
					setInfo(result);
				})
				.catch(err => {
					bbLogger.logError(err);
					const errorObj = new Error(JSON.stringify(err,errorReplacer));
					errorReporting.report(errorObj);
					setInfo({
						country: "us",
						connection: "mobile"
					})
				})

		})
	}
	/**
	 * Sets the information in the config
	 * 
	 * @param {ClientInformation} clientInformation 
	 * @private
	 * @memberof clientInfo
	 */
	function setInfo(clientInformation){
		setConfig("clientInfo", {
			...getConfig("clientInfo"),
			data: {
				...clientInformation,
				country: features.getValue('countryCode') || clientInformation.country
			}
		});
	}
	/**
	 * Function getter specifically for the country code.
	 * 
	 * @private
	 * @memberof clientInfo
	 */
	function getCountryCode(){
		if(getConfig("clientInfo.data.country")){
			return Promise.resolve(getConfig("clientInfo.data.country"));
		} else {
			return getInfo(true).then(v => v.country);
		}
	}
	/**
	 * Takes the configured API url and fetches the client information
	 * 
	 * @returns {Promise<ClientInformation>}
	 * @private
	 * @memberof clientInfo
	 */
	function getInfoFromApi(geoOnly) {
		let abortHandler = api.createAbort()
		inFlightReq = api.get("client-info", {useVersion: false, signal: abortHandler.signal, abortHandler})
		inFlightReq.geoOnly = geoOnly;
		inFlightReq.abort = abortHandler.abort.bind(abortHandler);
		return inFlightReq;
	}
	// Expose Client Info api
	exposureApi.expose({
		getClientInfo: getInfo
	});
	// Initialize module
	initialize();
	// Return private api
	return {
		getInfo,
		getCountryCode
	};
})();

// -----------------------------------
// ADDITIONAL DOCUMENTATION
// -----------------------------------

/**
 * Client information contains various data about a given client side user.
 * 
 * @typedef {Object} ClientInformation
 * 
 * @param {String} country two character country code. Follows the {@link https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes|ISO 3166-1 country code} conventions
 * @param {ConnectionType} connection the detected connection type with latency and speed considered
 * @param {String} region two character country subdivision code. Follows the {@link https://en.wikipedia.org/wiki/ISO_3166-2|ISO 3166-2 region code} conventions
 * @param {String} postalCode detected postal code
 * @param {String} gmtOffset The GMT timezone offset
 */

 /**
  * Connection Type
  * 
  * Detected connection type that implies different latencies as well as throughput.
  * 
  * Possible values: `broadband`, `cable`, `dialup`, `mobile`, `oc12`, `oc3`, `t1`, `t3`, `satellite`, `wireless`, `xdsl`
  * 
  * @typedef {String} ConnectionType
  * 
  */