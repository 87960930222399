/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import isEqual from 'lodash/isEqual.js';
import { bbLogger } from './logger.js';
// eslint-disable-next-line import/no-named-default
import { default as _get } from './helpers/get.js';
// eslint-disable-next-line import/no-named-default
import { default as _set } from './helpers/set.js';
import { makeSafe } from './safeFunction.js';
import { hookedFn } from './hookedFunction.js';
import { cloneDeep } from './cloneDeep.js';
import isEmpty from './helpers/isEmpty.js';
import omit from './helpers/omit.js';
import defaultsDeep from './helpers/defaultsDeep.js';
import { expireAction } from './clientExpire.js';

const ALL_TOPICS = '*';
const DEFAULT_OPTIONS = { versionControl: false };

export const richObject = (...args) => {
	let _value = {};
	let _meta = {};
	const listeners = {};
	let options = DEFAULT_OPTIONS;

	function getMeta() {
		return _meta;
	}
	function getChangedTopics(newVal, oldVal) {
		return Object.keys(listeners).filter((topicKey) => !isEqual(_get(newVal, topicKey), _get(oldVal, topicKey)));
	}
	function getValue(topic = null, listener = null) {
		if (!listener) {
			const option = topic;
			return option && option !== ALL_TOPICS ? _get(_value, option) : _value;
		}

		// eslint-disable-next-line no-use-before-define
		return subscribe(topic, listener);
	}
	function callSubscribers(changedTopics, setOptions) {
		// call subscribers of a specific topic, passing only that configuration
		for (let index = 0; index < changedTopics.length; index += 1) {
			const topicKey = changedTopics[index];
			if (listeners[topicKey] && Array.isArray(listeners[topicKey])) {
				for (let i = 0; i < listeners[topicKey].length; i += 1) {
					const listener = listeners[topicKey][i];
					if (getValue(topicKey) !== undefined) {
						makeSafe(() => listener(getValue(topicKey), setOptions));
					}
				}
			}
		}

		// call subscribers that didn't give a topic, passing everything that was set
		if (listeners[ALL_TOPICS]) {
			for (let index = 0; index < listeners[ALL_TOPICS].length; index += 1) {
				const listener = listeners[ALL_TOPICS][index];
				makeSafe(() => listener(getValue(ALL_TOPICS), setOptions));
			}
		}
	}
	function subscribe(topic, listener) {
		let callback = listener;

		if (typeof topic !== 'string') {
			callback = topic;
			topic = ALL_TOPICS;
		}

		if (typeof callback !== 'function') {
			bbLogger.logError('listener must be a function');
			return;
		}

		if (!listeners[topic]) {
			listeners[topic] = [];
		}

		listeners[topic].push(callback);

		if (getValue(topic) !== undefined) {
			makeSafe(() => listener(getValue(topic), { id: 'initialSet' }));
		}

		// eslint-disable-next-line consistent-return
		return function unsubscribe() {
			listeners[topic].splice(listeners[topic].indexOf(callback), 1);
		};
	}
	const setValue = hookedFn('sync', (topic, value, setOptions) => {
		const _oldObject = cloneDeep(_value);
		if (typeof topic !== 'string') {
			value = topic;
			topic = ALL_TOPICS;
		}

		if (typeof value !== 'object' && topic === ALL_TOPICS) {
			bbLogger.logError('When setting all topics: value must be an object');
			return;
		}
		if (topic === ALL_TOPICS) {
			if (isEmpty(value)) {
				_value = {};
			} else if (setOptions && setOptions.forceUpdate) {
				_value = value;
			} else {
				_value = defaultsDeep({}, value, _value);
			}
		} else {
			_set(_value, topic, value);
			if (setOptions) {
				// eslint-disable-next-line no-use-before-define
				const evaluatedSetOptions = applySetOptions(topic, setOptions);
				if (evaluatedSetOptions) {
					_set(_meta, topic, evaluatedSetOptions);
				}
			}
		}

		const changedTopicKeys = getChangedTopics(_value, _oldObject);

		callSubscribers(changedTopicKeys, setOptions);
	});
	const deleteKey = (key) => {
		const keySet = Array.isArray(key) ? key : [key];
		_value = omit(_value, keySet);
		_meta = omit(_meta, keySet);
		setValue(_value);
	};
	function applySetOptions(topic, setOptions) {
		if (setOptions.expires) {
			const expireTs = expireAction(setOptions.expires, () => {
				deleteKey(topic);
			});

			return defaultsDeep(setOptions, { expires: expireTs });
		}
		return setOptions;
	}
	function setMeta(value) {
		Object.keys(value).forEach((topic) => {
			if (Object.prototype.hasOwnProperty.call(value, topic)) {
				const setOptions = value[topic];
				applySetOptions(topic, setOptions);
			}
		});
	}
	function __constructor(initValue = {}, providedOptions = DEFAULT_OPTIONS) {
		if (!Array.isArray(initValue) && typeof initValue === 'object') {
			_value = initValue;
			options = Object.assign(options, providedOptions);
		} else {
			bbLogger.logWarn('Initial value not picked up for eventful object. Invalid type.', initValue);
		}
		if (options.syncValue) {
			getValue('*', (value) => {
				if (Array.isArray(options.syncValue)) {
					for (let index = 0; index < options.syncValue.length; index += 1) {
						const syncFn = options.syncValue[index];
						syncFn(value);
					}
				} else if (typeof options.syncValue === 'function') {
					options.syncValue(value);
				}
			});
		}
	}
	__constructor(...args);

	return {
		getValue,
		setValue,
		deleteKey,
		getMeta,
		setMeta,
		getOptions: () => options,
		get value() {
			return cloneDeep(_value);
		},
		get options() {
			return cloneDeep(options);
		},
	};
};

export default richObject;

// module.exports = { richObject };

// const myVar = richObject({}, {versionControl: true});

// myVar.setValue("a.b.c", 'someValue');

// myVar.getValue();
// // {a: { b: { c: 'someValue'}}};
// let unsubscribe = myVar.getValue('a.b', bValue => {
//     console.log(bValue);
// })

// myVar.setValue("a.b.d", 5);
// // {c: 'someValue', d: 5 }

// unsubscribe();

// let revision = myVar.setValue("a.b.z", 7);
// console.log(revision.version);
// // 6bbd3b81-de65-4f42-acd7-908acd1d7f06
// myVar.getValue();
// // {a: { b: { c: 'someValue', d: 5, z: 7 }}};

// revision.revertTo();'
// myVar.getVersion();
// // 6bbd3b81-de65-4f42-acd7-908acd1d7f06

// let newRevisionFromRevert = revision.revertTo(true);
// console.log(newRevisionFromRevert.version)
// // 9fde2786-3956-4f56-bd33-d87f0c8d84a9
// myVar.getVersion()
// // 9fde2786-3956-4f56-bd33-d87f0c8d84a9

// mayVar.getValue();
// // {a: { b: { c: 'someValue', d: 5}}};
